import React, {useState} from 'react';
import {Button, Table, theme} from "antd";
import {ColumnsType} from "antd/es/table";
import {QueryKey, useQueryClient} from "@tanstack/react-query";
import {useCommonUseQuery} from "../../../common/hooks/rest/ReacQueryHook";
import Search from "antd/es/input/Search";
import {formatDate} from "../../../common/util/DateUtil";
import UserAvatar from "../../index/header/UserAvatar/UserAvatar";
import {procGetUserList, UserData} from "../../../rest/admin/UserMgr";

const UserMgr: React.FC = () => {
    const { token: { colorBgContainer },} = theme.useToken();
    const [ userOpt, setUserOpt] = useState<UserData>({
        deptCD:null,
        deptName:null,
        burkCd:null,
        burkName:null,
        userId:null,
        userName:null,
        userStatus:null,
        createTime:null,
        updateTime:null,
    });
    const queryClient = useQueryClient();
    const queryKey:QueryKey =   [`UserMgr`];
    const { data} = useCommonUseQuery<UserData[]>(
        queryKey,
        () =>procGetUserList(userOpt));

    const onSearch = (value: string) => console.log(value);


    const columns: ColumnsType<UserData> = [
        {
            title: '',
            dataIndex: 'userName',
            key: 'userName',
            render: (text: string) =>
                <div style={{ paddingBottom: '9px', margin:'0', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <UserAvatar userName={text}/>
                </div>
            ,
        },
        {
            title: 'ID',
            dataIndex: 'userId',
            key: 'userId',
            render: (text: string, record:UserData) =>
                <div style={{ paddingBottom: '9px', margin:'0', display: 'flex', alignItems: 'left', justifyContent: 'left' }}>
                    {record.userName} ({text})
                </div>
            ,
        },
        {
            title: '소속법인',
            dataIndex: 'burkCd',
            key: 'burkCd',
            render: (text: string,record:UserData) =>
                <div style={{ paddingBottom: '9px', margin:'0', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    {record.burkName} ({text})
                </div>
            ,
        },
        {
            title: '소속부서',
            dataIndex: 'deptCD',
            key: 'deptCD',
            render: (text: string,record:UserData) =>
                <div style={{ paddingBottom: '9px', margin:'0', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    {record.deptName} ({text})
                </div>
            ,
        },
        {
            title: '생성일자',
            key: 'createTime',
            dataIndex: 'createTime',
            render: (text: string, record:UserData) =>
                <div style={{ paddingBottom: '9px', margin:'0', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    {formatDate(text)}
                </div>
            ,
        },
        {
            title: '마지막접속일자',
            key: 'updateTime',
            dataIndex: 'updateTime',
            render: (text: string, record:UserData) =>
                <div style={{ paddingBottom: '9px', margin:'0', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    {formatDate(text)}
                </div>
            ,
        },
        {
            title: '등급',
            key: 'userStatus',
            dataIndex: 'userStatus',
            render: (text: boolean, record:UserData) =>
                <div style={{ paddingBottom: '9px', margin:'0', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    {text ? '일반' : '관리자'}
                </div>
            ,
        },
        {
            title: '',
            key: 'Active',
            dataIndex: 'Active',
            render: (text, record:UserData) =>
                <div style={{ paddingBottom: '9px', margin:'0', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Button type="link" size="small">권한변경</Button>
                    <Button type="link" size="small">초기화</Button>
                </div>
            ,
        },
    ];

    return (
        <>
        <nav style={{
            margin:'0px 0px 14px 0px',
            padding:'9px 8px 9px 0px',
            height: '50px',
            display:'flex',
            justifyContent: "space-between",
            alignItems:'center',
            borderRadius: "8px", // 라운드 처리 추가
            border: '1px solid #dadada',
            background: colorBgContainer,
        }}>
            <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{
                    padding: '9px 5px',
                    display:'flex',
                }}>
                </div>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
                <Search placeholder="검색어를 입력해보세요." allowClear onSearch={onSearch} style={{ width: 210 }} /> <Button type="primary" size="small">조회</Button>
            </div>
        </nav>
        <Table columns={columns}
               dataSource={data}
               rowKey="userId"
        />
        </>
    );
};

export default UserMgr;
