import React, {useContext, useState} from 'react';
import { Modal } from 'antd';
import VersionList from "./VersionList";
import ViewerDetailContext from "../../../../../context/ViewerDetailContext";

const VersionMgrModal = () => {

    const {showVersion, setShowVersion, docId } = useContext(ViewerDetailContext);
    return (

        <Modal
            title="문서 버전 관리"
            centered
            open={showVersion as boolean}
            onCancel={() =>setShowVersion(false)}
            footer={[
            ]}
        >
            {docId && showVersion &&
            <VersionList/>}
        </Modal>
    );
};

export default VersionMgrModal;