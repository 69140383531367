import {Button, Divider, Tabs, TabsProps} from "antd";
import React, {useContext} from "react";
import "./css/FileInfo.css"
import {DocumentList} from "../data/DocumentList";
import useDownloadToURL from "../../../../../common/hooks/rest/useDownloadToURL";
import {HistoryActiveCode} from "../data/HistoryActiveCode";
import UserAvatar from "../../../header/UserAvatar/UserAvatar";
import {procGetHistory} from "../../../../../rest/docuwizViewer/HistoryRest";
import {useCommonUseQuery} from "../../../../../common/hooks/rest/ReacQueryHook";
import {QueryKey} from "@tanstack/react-query";
import {formatDate} from "../../../../../common/util/DateUtil";
import ViewerDetailContext from "../../../../../context/ViewerDetailContext";

const onChange = (key: string) => {
    console.log(key);
};

interface SlipdocContentProps{
    fileInfo:DocumentList,
}

export interface HistoryData{
    historyId:string,
    activeCD:string,
    folderId:string,
    docId:string,
    docVersion:number,
    userId:string,
    userName:string,
    firstPrgId:string,
    lastPrgId:string,
    firstRegTime:string,
    lastRegTime:string,
}
function getHistoryMsg(historydata:HistoryData ):string {
    let historyTxt:string = "";
    const userInfo = `${historydata.userId}(${historydata.userName})`
    switch (historydata.activeCD) {
        case HistoryActiveCode.FILE_CREATE:
            historyTxt = `${userInfo}이(가) 파일을 업로드했습니다.`;
            break;
        case HistoryActiveCode.FILE_VERSION_UP:
            historyTxt = `${userInfo}이(가) 파일을 ${historydata.docVersion}.0으로 버전업했습니다.`;
            break;
        case HistoryActiveCode.FILE_DELETE:
            historyTxt = `${userInfo}이(가) 파일을 삭제 했습니다.`;
            break;
        case HistoryActiveCode.FILE_DOWNLOAD:
            historyTxt = `${userInfo}이(가) 파일을 다운로드 했습니다.`;
            break;
        case HistoryActiveCode.FOLDER_CREATE:
            historyTxt = `${userInfo}이(가) 폴더을 생성 했습니다.`;
            break;
        case HistoryActiveCode.FOLDER_DELETE:
            historyTxt = `${userInfo}이(가) 폴더을 삭제 했습니다.`;
            break;
    }

    return historyTxt;

}

const FileInfoContent = (props:SlipdocContentProps) => {

    const url:string = "rest/docuwiz/document/download/file";
    const fileId:string = props.fileInfo.previewId;
    const fileType:number = props.fileInfo.prev_file_type;
    const fileStatus:number =   props.fileInfo.cnvt_status;

    let isDownload:boolean  =   false;
    if( (fileType === 0 && fileStatus===1) || (fileType >= 1 &&fileType <= 2) )
    {
        isDownload =true;
    }

    const { downloadUrl } = useDownloadToURL({
        url: url, // The download API endpoint
        fileId: fileId, // The ID of the file to download
        isDownload: isDownload, // Set to true to start the download immediately
    });
    const tempUrl:string  = "/image/base_thumb/docu.svg";

    const queryKey:QueryKey =   [`fileinfo_${props.fileInfo.docId}`];

    const { setDocId, setShowVersion} = useContext(ViewerDetailContext);
    const { data, isLoading, isError } = useCommonUseQuery<HistoryData[]>(
        queryKey,
        () =>procGetHistory(props.fileInfo.docId));

    const items: TabsProps['items'] = [
        {
            key: '1',
            label: `세부 정보`,
            children:
                <div style={{overflowY:"auto", height:"calc(100vh - 110px)"}}>
                    <div className="fileInfo-Thumb">
                        <img src={downloadUrl ? downloadUrl : tempUrl} alt={props.fileInfo.fileName} />
                    </div>
                    <Divider />
                    <div style={{marginBottom:'10px', fontWeight:'500', fontSize:'16px'}}>
                        파일 세부 정보
                    </div>

                    <div style={{marginTop:'10px', marginBottom:'10px'}}>
                        <span style={{fontSize:'13px',fontWeight:'bold'}}>유형</span><br/>
                        <span>{props.fileInfo.extension.toUpperCase()}</span>
                    </div>

                    <div style={{marginTop:'10px', marginBottom:'10px'}}>
                        <span style={{fontSize:'13px',fontWeight:'bold'}}>크기</span><br/>
                        <span>{props.fileInfo.formatFileSize}</span>
                    </div>
                    <div style={{marginTop:'10px', marginBottom:'10px'}}>
                        <span style={{fontSize:'13px',fontWeight:'bold'}}>문서버전</span><br/>
                        <span>{props.fileInfo.fileVersion}.0</span>
                        <span style={{marginLeft:'10px'}}><Button type="primary" size="small" onClick={(e) => {
                            setDocId(props.fileInfo.docId);
                            setShowVersion(true);
                        }}>버전관리</Button></span>
                    </div>
                    <div style={{marginTop:'10px', marginBottom:'10px'}}>
                        <span style={{fontSize:'13px',fontWeight:'bold'}}>업로드 부서</span><br/>
                        <span>{props.fileInfo.deptCd} - {props.fileInfo.deptName}</span>
                    </div>
                    <div style={{marginTop:'10px', marginBottom:'10px'}}>
                        <span style={{fontSize:'13px',fontWeight:'bold'}}>업로드 사용자</span><br/>
                        <span>{props.fileInfo.userId} - {props.fileInfo.userName}</span>
                    </div>
                    <div style={{marginTop:'10px', marginBottom:'10px'}}>
                        <span style={{fontSize:'13px',fontWeight:'bold'}}>업로드 날짜</span><br/>
                        <span>{ formatDate(props.fileInfo.createTime)}</span>
                    </div>
                    <div style={{marginTop:'10px', marginBottom:'10px'}}>
                        <span style={{fontSize:'13px',fontWeight:'bold'}}>마지막 수정 날짜</span><br/>
                        <span>{formatDate(props.fileInfo.modifyTime)}</span>
                    </div>

                </div>

            ,
        },
        {
            key: '2',
            label: `활동 내역`,
            children:
            <div style={{overflowY:"auto", height:"calc(100vh - 110px)"}}>
                {data &&
                    data.map((item, index) => (

                        <div key={index} style={{paddingTop:'10px', marginTop:'10px',  borderBottom:'1px solid lightgrey', paddingBottom:'20px', marginBottom:'10px', display:"flex", justifyContent:"space-between"}}>
                            <div style={{width:'50px'}}>
                                <UserAvatar userName={item.userName}/>
                            </div>
                            <div style={{width:'calc(100% - 50px)'}}>
                                <span style={{fontSize:'14px'}}>{getHistoryMsg(item)}</span><br/>
                                <span style={{fontSize:'12px'}}>{formatDate(item.firstRegTime)}</span>
                            </div>
                        </div>
                    ))
                }
            </div>
            ,
        },
    ];

    return (
        <div style={{margin:'10px'}}>
            <div style={{width:'calc(100% - 25px)', fontSize:'16px', fontWeight:'500', marginBottom:'10px'}}>
                {props.fileInfo.fileName}
            </div>
            <Tabs defaultActiveKey="1" items={items} onChange={onChange} />

        </div>
    );
};

export default FileInfoContent;