import React, {CSSProperties} from "react";
import {PDFViewer} from "../../../common/component/PDF/PDFViewer";
import useDownloadToURL from "../../../common/hooks/rest/useDownloadToURL";
import {ViewerType} from "./data/ViewerType";
import {ImageViewer} from "../../../common/component/Image/ImageViewer";
import {Button} from "antd";
import useDownloadFile from "../../../common/hooks/rest/useDownloadFile";
import LoadingContainer from "../../../common/component/LoadingContainer/LoadingContainer";

function FullViewer(props : ViewerType)
{
    const url:string = "rest/docuwiz/document/download/file";
    const fileId:string = props.previewId;
    const { downloadUrl, downloadStatus } = useDownloadToURL({
        url: url, // The download API endpoint
        fileId: fileId, // The ID of the file to download
    });

    const downloadFile = useDownloadFile();
    const overlayStyle:CSSProperties = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'fixed',
        top: '51px',
        left: '0',
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.6)'
    };

    const messageStyle:CSSProperties = {
        color: 'white',
        fontSize: '18px',
        textAlign: 'center',
        backgroundColor: '#3b393b',
        width:'50%',
        height:'100px',
        borderRadius:'20px',
        padding:'20px',
    };

    if(downloadUrl !== null)
    {
        if ((props.previewType ===0 && props.convertStatus===1) || props.previewType === 1) {
            return (
                downloadUrl ? <PDFViewer fileUrl={downloadUrl} fileName={props.fileName} docId={props.docId} version={props.version}/> : <></>
            );
        }
        else if (props.previewType === 2 )
        {
            return (
                downloadUrl ? <ImageViewer fileUrl={downloadUrl}  fileName={props.fileName}/> : <></>
            );
        }
    }
    else
    {
        return (
            downloadStatus === 'downloading' ?
            <LoadingContainer message={"문서를 다운로드 중입니다."}/>
                :
            <div style={overlayStyle}>
                <div style={messageStyle}>
                    <div style={{marginBottom:'12px'}}>미리보기를 할 수 없는 파일이거나 미리보기가 없습니다.</div>
                    <Button type="primary"onClick={(e) => {
                        e.stopPropagation();
                        const url:string = "rest/docuwiz/document/download";
                        const docId:string = props.docId;
                        const docVersion:number = props.version;
                        const defFileName:string = props.fileName;
                        downloadFile({
                            url: url,
                            docId: docId,
                            defFileName: defFileName,
                            docVersion: docVersion
                        }).then();}}>다운로드</Button>

                </div>
            </div>
        );

    }

    return (
        <></>
    );


}

export default FullViewer;