import React, {FC} from "react";
import FileMgrDashBoard from "../FileMgr/FileMgrDashBoard";
import FileMgrExtention from "../FileMgr/FileMgrExtention";
import FileMgrDocument from "../FileMgr/FileMgrDocument";
import UserMgrDashBoard from "../UserMgr/UserMgrDashBoard";
import UserMgr from "../UserMgr/UserMgr";
import MonitorDocuwizRest from "../Monitoring/MonitorDocuwizRest";
import MonitorSapRfc from "../Monitoring/MonitorSapRfc";
import MonitorSapRCMS from "../Monitoring/MonitorRCMS";
import MonitorEZBARO from "../Monitoring/MonitorEZBARO";
import AesMgr from "../Security/AesMgr";
import JwtMgr from "../Security/JwtMgr";
import StorageRest from "../Storage/StorageRest";
import StorageMgr from "../Storage/StorageMgr";
import StorageInfra from "../Storage/StorageInfra";
import MonitorStorageWizRest from "../Monitoring/MonitorStorageWizRest";


type RenderComponentProps = {
    menuKey: string;
};
const RenderComponent: FC<RenderComponentProps> = ({ menuKey }) => {

    switch (menuKey) {
        case '파일관리/현황':
            return <FileMgrDashBoard />;
        case '파일관리/확장자관리':
            return <FileMgrExtention />;
        case '파일관리/문서관리':
            return <FileMgrDocument />;
        case '사용자관리/현황':
            return <UserMgrDashBoard />;
        case '사용자관리/권한관리':
            return <UserMgr/>;
        case '모니터링/DB 트랜젝션':
            return <MonitorDocuwizRest/>;
        case '모니터링/파일 트랜젝션':
            return <MonitorStorageWizRest/>;
        case '모니터링/SAP RFC':
            return <MonitorSapRfc/>;
        case '모니터링/RCMS':
            return <MonitorSapRCMS/>;
        case '모니터링/EZBARO':
            return <MonitorEZBARO/>;
        case '보안관리/파일암호화':
            return <AesMgr/>;
        case '보안관리/로그인토큰':
            return <JwtMgr/>;
        case '스토리지관리/RESTAPI설정':
            return <StorageRest/>;
        case '스토리지관리/경로설정':
            return <StorageMgr/>;
        case '스토리지관리/인프라설정':
            return <StorageInfra/>;



        default:
            return <div>{menuKey}</div>;
    }


};

export default RenderComponent;