import {useQuery, useMutation, QueryKey, useInfiniteQuery, QueryClient} from '@tanstack/react-query';
import axiosInstance from '../../util/AxiosInstance';

export async function request<T>  (urlCode: string, method: string, data: T, isMutipart:boolean): Promise<T>{

     try {
        const setMethod = method.toUpperCase();
        const response = await axiosInstance.request({
            method,
            url:  `${urlCode}`,
            headers: {
                'Content-Type': !isMutipart ? 'application/json' : 'multipart/form-data',
            },
            params:  setMethod === "GET" ? data : {},
            data:    setMethod !== "GET" ? data : {}
        });
        return response.data;
    } catch (error) {
        throw new Error("error");
    }
};

export async function requestInfiniteQuery<T>  (urlCode: string, method: string, data: T, isMutipart:boolean, pageParam:number): Promise<T>{

    try {
        const setMethod = method.toUpperCase();
        const page  =   pageParam === null ? 1: pageParam;
        const response = await axiosInstance.request({
            method,
            url: page > 0 ?  `${urlCode}/${page}` : `${urlCode}`,
            headers: {
                'Content-Type': !isMutipart ? 'application/json' : 'multipart/form-data',
            },
            params:  setMethod === "GET" ? data : {},
            data:    setMethod !== "GET" ? data : {}
        });
        return response.data;
    } catch (error) {
        throw new Error("error");
    }
};

export function useCommonUseQuery<T>(queryKey: QueryKey,  requestCallback: () => Promise<T>) {
    return useQuery<T, Error>(queryKey, requestCallback,
        {
            refetchOnWindowFocus: false,
            refetchOnMount: true,
            refetchOnReconnect: true,
        });
}
export function useCommonUseMutation<T>(
    queryKey: QueryKey,
    queryClient: QueryClient,
    mutateCallback: (data: any) => Promise<T>,
) {
    return useMutation<T, Error, any>(
        (data: any) => mutateCallback(data),
        {
            onSuccess: (data) => {
                queryClient.invalidateQueries(queryKey).then();
            },
            onError: (error) => {
                console.error(error);
            },
        }
    );
}
export function useCommonUseInfiniteQuery<T>(
    queryKey: QueryKey,
    requestParams: any,
    fetchInfiniteQueryCallback: (requestParams: any, pageParam: number) => Promise<T>,
    dataAccessor: (response: T) => any[]
) {
    return useInfiniteQuery<T, Error>(
        queryKey,
        ({ pageParam = 1 }) => fetchInfiniteQueryCallback(requestParams, pageParam),
        {
            getNextPageParam: (lastPage, allPages) => {
                if (dataAccessor(lastPage).length === 0) {
                    return null;
                } else {
                    const nextPage = allPages.length + 1;
                    return nextPage;
                }
            },
            refetchOnWindowFocus: false,
            refetchOnMount: true,
            refetchOnReconnect: true,
        }
    );
}
