import React, {useEffect, useState} from 'react';
import {Layout, theme} from 'antd';
import './Layout.css';

import CopyToClipboard from "../../component/index/header/CopyToClipbord/CopyToClipbord";
import UserAvatar from "../../component/index/header/UserAvatar/UserAvatar";
import ContentMain from "../../component/index/content/main/ContentMain";
import {useParams} from "react-router-dom";
import {RequestOption} from "../../data/RequestOption";
import {ViewerOption} from "../../data/ViewerOption";
import ViewerOptionContext from "../../context/ViewerOptionContext";
import {procGetOption} from "../../rest/docuwizViewer/OptionRest";
import LoadingContainer from "../../common/component/LoadingContainer/LoadingContainer";
import ErrPage from "../../common/pages/ErrPage";
import {useCommonUseQuery} from "../../common/hooks/rest/ReacQueryHook";
import {QueryKey} from "@tanstack/react-query";

const { Header, Content } = Layout;


const DocuwizLayout: React.FC = () => {
    const { encryptInterfaceId } = useParams() as { encryptInterfaceId: string };
    const { encryptMode } = useParams() as { encryptMode: string };
    const requestPageSize:number       =   parseInt(process.env.REACT_APP_REQUET_PAGE_SIZE as string) ;

    const [viewerOtp, setViewerOtp] = useState<ViewerOption|null>(null);

    const queryKey:QueryKey =   [encryptInterfaceId];

    const requestParam : RequestOption =   {
        encryptInterfaceId: encryptInterfaceId,
        encryptInterfaceMode: encryptMode,
        requestPageSize:requestPageSize,
    }

    const { data, isLoading, isError } = useCommonUseQuery<ViewerOption>(
        queryKey,
        () =>procGetOption(requestParam));



    const {
        token: { colorBgContainer },
    } = theme.useToken();

    useEffect(() => {
        if (data)
        {
            setViewerOtp(data);
            if (viewerOtp?.jwtSession)
            {
                sessionStorage.setItem("user_nm", viewerOtp.jwtSession.userName)
            }
        }

    }, [data]);

    if(viewerOtp !&& !isLoading && !isError)
    {
        return (
            <ViewerOptionContext.Provider value={{ viewerOption:viewerOtp, setViewerOption:setViewerOtp}}>
                <Layout style={{ minHeight: '100vh' }}>
                    <Header style={{
                        padding: '8px 9px',
                        background: colorBgContainer,
                        height: '50px',
                        display:'flex',
                        justifyContent:'space-between',
                        alignItems:'center',
                        borderBottom: '1px solid #dadada',

                    }}>
                        <CopyToClipboard copyString={viewerOtp?.interfaceId as string}/>
                        {viewerOtp?.jwtSession && <UserAvatar userName={viewerOtp?.jwtSession.userName as string}/>}
                    </Header>
                    <Content  style={{
                        margin:'8px 8px 8px 8px',
                        minHeight: 200,
                        height: 'calc(100vh - 80px)',

                    }}
                    >
                        <ContentMain/>
                    </Content>
                </Layout>
            </ViewerOptionContext.Provider>
        );
    }

    if(isLoading){
        return (
            <LoadingContainer message="초기데이터를 불러오는 중..."/>
        );
    }
    else {
        return (
            <ErrPage errCode={9998}/>
        );
    }

};

export default DocuwizLayout;