import React from 'react';
import {Avatar} from 'antd';
import {getFileColor} from "../../../../../common/util/ColorUtil";

interface FileAvatarProps  {
    extension:string,
    encryptInterfaceId? : string,
    docId? :string,

}
const FileAvatar = (props:FileAvatarProps) => {
    const backgroundColor = getFileColor(props.extension);
    const openDetail = () =>
    {
        if (props.encryptInterfaceId && props.docId)
        {
            const popUpOptions:string = "toolbar=no,scrollbars=no,resizable=yes,status=no,menubar=no,width=1200, height=800, top=0,left=0";
            window.open("../../view/"+props.encryptInterfaceId+"/"+props.docId, props.encryptInterfaceId, popUpOptions);
        }

    }

    return (
            <Avatar
                style={{ padding: '0', margin:'0', backgroundColor: backgroundColor, verticalAlign: 'middle', cursor: 'pointer' }}
                size="large"
                shape="square"
                onClick={openDetail}
            >
                <b>{props.extension.toUpperCase()}</b>
            </Avatar>
    );
};

export default FileAvatar;