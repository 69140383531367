import axiosInstance from "../../common/util/AxiosInstance";

export interface RestApiOption {
    traceId:string|null,
    startTime:string|null,
    endTime:string|null,
    userId:string|null,
    success:boolean|null,
}

export interface TB_MONITORING {
    traceId:string,
    loginId:string,
    clientIP:string,
    success:boolean,
    runTime:number,
    reqUrl:string,
    prevUrl:string,
    responseCode:number,
    method:string,
    charset:string,
    requestHeader:string,
    requestBody:string,
    responseHeader:string,
    responseBody:string,
    exceptionTxt:string,
}


export async function  procGetRestApiList(restOpt:RestApiOption):Promise<TB_MONITORING[]>{

    const response = await axiosInstance.request<TB_MONITORING[]>(
        {
            method: "GET",
            url: `rest/docuwiz/admin/monitor/restapi/select`,
            headers: {
                'Content-Type': 'application/json',
            },
            params:restOpt,
        });

    return response.data;
};

