import React, {useState} from 'react';
import {Button, Table, theme} from "antd";
import {ColumnsType} from "antd/es/table";
import {QueryKey, useQueryClient} from "@tanstack/react-query";
import {useCommonUseQuery} from "../../../common/hooks/rest/ReacQueryHook";
import Search from "antd/es/input/Search";
import {formatDate} from "../../../common/util/DateUtil";
import UserAvatar from "../../index/header/UserAvatar/UserAvatar";
import {procGetUserList, UserData} from "../../../rest/admin/UserMgr";
import {procGetAesMgrList, TB_SECURITY_AES} from "../../../rest/admin/SecurityAesMgr";
import {procGetJwtMgrList, TB_SECURITY_JWT} from "../../../rest/admin/SecurityJwtMgr";
import {procGetStorageWizUrlList, TB_STORAGEWIZ} from "../../../rest/admin/StorageWizMgr";

const StorageRest: React.FC = () => {
    const { token: { colorBgContainer },} = theme.useToken();
       const queryClient = useQueryClient();
    const queryKey:QueryKey =   [`storage/storage`];
    const { data} = useCommonUseQuery<TB_STORAGEWIZ[]>(
        queryKey,
        () =>procGetStorageWizUrlList());


    const columns: ColumnsType<TB_STORAGEWIZ> = [
        {
            title: 'url종류',
            dataIndex: 'urlType',
            key: 'urlType',
            render: (text: string) =>
                <div style={{ paddingBottom: '9px', margin:'0', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    {text.toUpperCase()}
                </div>
            ,
        },
        {
            title: 'StorageWiz 경로',
            dataIndex: 'url',
            key: 'url',
            render: (text: string) =>
                <div style={{ paddingBottom: '9px', margin:'0', display: 'flex', alignItems: 'left', justifyContent: 'left' }}>
                    {text}
                </div>
            ,
        },
        {
            title: '생성일자',
            key: 'lastRegTime',
            dataIndex: 'lastRegTime',
            render: (text: string) =>
                <div style={{ paddingBottom: '9px', margin:'0', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    {formatDate(text)}
                </div>
            ,
        },

    ];

    return (
        <>
        <Table columns={columns}
               dataSource={data}
               rowKey="jwtKey"
        />
        </>
    );
};

export default StorageRest;
