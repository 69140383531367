import React from 'react';
import { Row, Col } from 'antd';
import LineChart, {chartContent} from "../common/LineChart";
const UserMgrDashBoard: React.FC = () => {
    const getColProps = () => {
        const baseProps = {xs: 24, sm: 12, md: 12, lg: 12, xl: 12, xxl: 12};
        return baseProps;
    }

    function getRandomNumberInRange(min: number, max: number): number {
        if (min > max) {
            throw new Error('Min value should not be greater than Max value');
        }
        return Math.floor(Math.random() * (max - min + 1) + min);
    }

    const todayCategory = [
        '00','01','02','03','04','05',
        '06','07','08','09','10','11',
        '12','13','14','15','16','17',
        '18','19','20','21','22','23',
    ]

    const todaySeries:chartContent[] = [
        {
            name: "오늘",
            data: [
                getRandomNumberInRange(0,10), 0, 0, 0, 0, 0,
                0, 0, getRandomNumberInRange(0,10), getRandomNumberInRange(0,100), getRandomNumberInRange(0,100), getRandomNumberInRange(0,100),
                getRandomNumberInRange(0,100), getRandomNumberInRange(0,100), getRandomNumberInRange(0,100), getRandomNumberInRange(0,100), getRandomNumberInRange(0,100), getRandomNumberInRange(0,100),
                getRandomNumberInRange(0,100), getRandomNumberInRange(0,100), getRandomNumberInRange(0,30), getRandomNumberInRange(0,20), 0, 0,
            ]
        },
    ]

    const monthCategory = [
        '01','02','03','04','05','06',
        '07','08','09','10','11','12',
        '13','14','15','16','17','18',
        '19','20','21','22','23','24',
        '25','26','27','28','29','30','31',
    ]

    const monthSeries:chartContent[] = [
        {
            name: "이번달",
            data: [
                getRandomNumberInRange(200,500), getRandomNumberInRange(200,500), getRandomNumberInRange(200,500), getRandomNumberInRange(200,500), getRandomNumberInRange(200,500), getRandomNumberInRange(200,500),
                getRandomNumberInRange(200,500), getRandomNumberInRange(200,500), getRandomNumberInRange(200,500), getRandomNumberInRange(200,500), getRandomNumberInRange(200,500), getRandomNumberInRange(200,500),
                getRandomNumberInRange(200,500), getRandomNumberInRange(200,500), getRandomNumberInRange(200,500), getRandomNumberInRange(200,500), getRandomNumberInRange(200,500), getRandomNumberInRange(200,500),
                getRandomNumberInRange(200,500), getRandomNumberInRange(200,500), getRandomNumberInRange(200,500), getRandomNumberInRange(200,500), getRandomNumberInRange(200,500), getRandomNumberInRange(200,500),
                getRandomNumberInRange(200,500), getRandomNumberInRange(200,500), getRandomNumberInRange(200,500), getRandomNumberInRange(200,500), getRandomNumberInRange(200,500), getRandomNumberInRange(200,500),
                getRandomNumberInRange(200,500),
            ]
        },
    ]

    const yearCategory = [
        '01','02','03','04',
        '05','06','07','08',
        '09','10','11','12',
    ]

    const yearSeries:chartContent[] = [
        {
            name: "2021년",
            data: [
                getRandomNumberInRange(90000,100000), getRandomNumberInRange(90000,100000), getRandomNumberInRange(90000,100000), getRandomNumberInRange(90000,100000),
                getRandomNumberInRange(90000,100000), getRandomNumberInRange(90000,100000), getRandomNumberInRange(90000,100000), getRandomNumberInRange(90000,100000),
                getRandomNumberInRange(90000,100000), getRandomNumberInRange(90000,100000), getRandomNumberInRange(90000,100000), getRandomNumberInRange(90000,100000),
            ],
        },
        {
            name: "2022년",
            data: [
                getRandomNumberInRange(90000,100000), getRandomNumberInRange(90000,100000), getRandomNumberInRange(90000,100000), getRandomNumberInRange(90000,100000),
                getRandomNumberInRange(90000,100000), getRandomNumberInRange(90000,100000), getRandomNumberInRange(90000,100000), getRandomNumberInRange(90000,100000),
                getRandomNumberInRange(90000,100000), getRandomNumberInRange(90000,100000), getRandomNumberInRange(90000,100000), getRandomNumberInRange(90000,100000),
            ],
        },
        {
            name: "2023년",
            data: [
                getRandomNumberInRange(90000,100000), getRandomNumberInRange(90000,100000), getRandomNumberInRange(90000,100000), getRandomNumberInRange(90000,100000),
                getRandomNumberInRange(90000,100000), 0, 0, 0,
                0, 0, 0, 0
            ],
        },
    ]

    const quarterCategory = [
        '1/4','2/4','3/4','4/4',
    ]

    const quarterSeries:chartContent[] = [
        {
            name: "2022년",
            data: [
                80, 91, 78, 101
            ],
        },
        {
            name: "2023년",
            data: [
                84, 88, 88, 110
            ],
        },
    ]

    return (
        <Row gutter={[16,16]}>
            <Col {...getColProps()}>
                <LineChart title="오늘 사용자 접속 현황" key={1} unit="건" categories={todayCategory} series={todaySeries} dataLabels={true}/>
            </Col>
            <Col {...getColProps()}>
                <LineChart title="이번달 사용자 접속 현황" key={2} unit="건" categories={monthCategory} series={monthSeries} dataLabels={true}/>
            </Col>
            <Col {...getColProps()}>
                <LineChart title="분기별 사용자 접속 현황 (최근2년)" key={3} unit="건" categories={quarterCategory} series={quarterSeries} dataLabels={true}/>
            </Col>
            <Col {...getColProps()}>
                <LineChart title="연도별 사용자 접속 현황 (최근2년)" key={4} unit="건" categories={yearCategory} series={yearSeries} dataLabels={false}/>
            </Col>
        </Row>
    );
};

export default UserMgrDashBoard;
