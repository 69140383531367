import axiosInstance from "../../common/util/AxiosInstance";
import {HistoryData} from "../../component/index/content/Viewer/FileInfo/FileInfoContent";

export async function  procGetHistory(docId:string):Promise<HistoryData[]>{

    const response = await axiosInstance.request<HistoryData[]>(
        {
            method: "GET",
            url: `rest/docuwiz/document/history/${docId}`,
            headers: {
                'Content-Type': 'application/json',
            }
        });

    return response.data;
};