import axiosInstance from "../../common/util/AxiosInstance";
import {RestApiOption, TB_MONITORING} from "./MonitorRestApi";

export interface TB_STORAGEWIZ {
    key:string,
    urlType:string,
    url:string,
    lastRegTime:string,
}

export interface TB_STORAGE_MGR {
    storageName:string,
    mainPath:string,
    limitCnt:string,
    lastRegTime:string,
}

export interface TB_STORAGE_OPTION {
    id:number,
    activeClassName:string,
    isAesEnabled:string,
}



export async function  procGetStorageWizUrlList():Promise<TB_STORAGEWIZ[]>{

    const response = await axiosInstance.request<TB_STORAGEWIZ[]>(
        {
            method: "GET",
            url: `rest/docuwiz/admin/storage/rest/select`,
            headers: {
                'Content-Type': 'application/json',
            },
        });

    return response.data;
};

export async function  procGetStorageWizStorageList():Promise<TB_STORAGE_MGR[]>{

    const response = await axiosInstance.request<TB_STORAGE_MGR[]>(
        {
            method: "GET",
            url: `storagewiz/admin/storage/select`,
            headers: {
                'Content-Type': 'application/json',
            },
        });

    return response.data;
};

export async function  procGetStorageWizStorageInfraList():Promise<TB_STORAGE_OPTION[]>{

    const response = await axiosInstance.request<TB_STORAGE_OPTION[]>(
        {
            method: "GET",
            url: `storagewiz/admin/storage/option/select`,
            headers: {
                'Content-Type': 'application/json',
            },
        });

    return response.data;
};

export async function  procGetStorageWizRestApiList(restOpt:RestApiOption):Promise<TB_MONITORING[]>{

    const response = await axiosInstance.request<TB_MONITORING[]>(
        {
            method: "GET",
            url: `storagewiz/admin/monitor/restapi/select`,
            headers: {
                'Content-Type': 'application/json',
            },
            params:restOpt,
        });

    return response.data;
};


