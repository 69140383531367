import axiosInstance from "../../util/AxiosInstance";

type DownloadOptions = {
    url: string;
    docId:string;
    docVersion:number;
    defFileName?: string;
};

const useDownloadFile = () => {
    const downloadFile = async ({ url, docId, docVersion, defFileName = 'default-file-name.ext' }: DownloadOptions) => {
        const axiosResponse = await axiosInstance.request(
            {
                method: "get",
                url: `${url}`,
                responseType : "blob",
                headers: {
                    "Content-Type": "multipart/form-data",
                },
                params: {docId : docId, docVersion:docVersion}
            });


        // a태그 Element를 생성해준다.
        const aElement = document.createElement('a');
        // 위에서 생성한 aElement변수에 href속성에 넣을 데이터를 설정해준다.
        const blobFile = window.URL.createObjectURL(new Blob([axiosResponse.data]));
        aElement.href = blobFile;

        // 파일명 설정
        aElement.download = defFileName;

        // document body 위에 생성한 a태그를 부착 시킨다.
        document.body.appendChild(aElement);

        // 부착 시킨 a태그를 Click!(이벤트 발생 시키기) 그러면 다운로드가 된다.
        aElement.click();
        setTimeout(() => {
            // 이제 더이상 필요 없으니 생성한 a태그를 1초후 삭제 시켜준다.
            aElement.remove();
        }, 1000);

    };

    return downloadFile;
};

export default useDownloadFile;