import axiosInstance from "../../common/util/AxiosInstance";
import {CommentData} from "../../component/index/content/Viewer/data/CommentData";

export async function  procGetCommentData(dodId:string):Promise<CommentData[]>{

    const response = await axiosInstance.request<CommentData[]>(
        {
            method: "GET",
            url: `rest/docuwiz/comment/select/${dodId}`,
            headers: {
                'Content-Type': 'application/json',
            },

        });

    return response.data;
};

export async function  procInsertCommentData(commentData:CommentData):Promise<CommentData>{

    const response = await axiosInstance.request<CommentData>(
        {
            method: "POST",
            url: `rest/docuwiz/comment/create`,
            headers: {
                'Content-Type': 'application/json',
            },
            data:{
                commentData
            }

        });

    return response.data;
};

export async function  procUpdateCommentData(commentData:CommentData):Promise<CommentData>{

    const response = await axiosInstance.request<CommentData>(
        {
            method: "POST",
            url: `rest/docuwiz/comment/update`,
            headers: {
                'Content-Type': 'application/json',
            },
            data:{
                commentData
            }

        });

    return response.data;
};


export async function  procDeleteCommentData(delParam:any):Promise<boolean>{

    const response = await axiosInstance.request<boolean>(
        {
            method: "DELETE",
            url: `rest/docuwiz/comment/delete`,
            headers: {
                'Content-Type': 'application/json',
            },
            data:{
                delParam
            }

        });

    return response.data;
};