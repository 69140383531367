import {Tabs, TabsProps} from "antd";
import React, {useRef, useState} from "react";
import "../FileInfo/css/FileInfo.css"
import {DocumentList} from "../data/DocumentList";
import {useCommonUseQuery} from "../../../../../common/hooks/rest/ReacQueryHook";
import {QueryKey} from "@tanstack/react-query";
import CommentInput from "./CommentInput";
import {CommentData} from "../data/CommentData";
import {procGetCommentData} from "../../../../../rest/comment/Comment";
import CommentContent from "./CommentContent";
import useContainerSize from "../../../../../common/hooks/useContainerSize";


interface SlipdocContentProps{
    fileInfo:DocumentList,
}


const CommentList = (props:SlipdocContentProps) => {

    const queryKey:QueryKey =   [`comment_${props.fileInfo.docId}`];
    const { data} = useCommonUseQuery<CommentData[]>(
        queryKey,
        () =>procGetCommentData(props.fileInfo.docId));

    const [ showReplyCommentId, setShowReplyCommentId] = useState<string>('');
    const containerRef = useRef<HTMLDivElement>(null);
    const containerSize = useContainerSize(containerRef);


    const items: TabsProps['items'] = [
        {
            key: '1',
            label: `Comment`,
            children:
            <>
            <div style={{overflowY:"auto", height:`calc(100vh - ${containerSize.height + 150}px)`}}>
                {data &&
                    data.map((item, index) => (
                        <CommentContent key={index} encDocId={props.fileInfo.docId} {...item} showReplyCommentId={showReplyCommentId} setShowReplyCommentId={setShowReplyCommentId}/>
                    ))
                }
            </div>
            <CommentInput docId={props.fileInfo.docId} setShowReplyCommentId={setShowReplyCommentId}/>
            </>
            ,
        },
    ];

    return (
        <div style={{margin:'10px'}}>
            <div ref={containerRef} style={{width:'calc(100% - 25px)', fontSize:'16px', fontWeight:'500', marginBottom:'10px'}}>
                {props.fileInfo.fileName}
            </div>
            <Tabs defaultActiveKey="1" items={items} />

        </div>
    );
};

export default CommentList;