import React from 'react';
import Chart from 'react-apexcharts';
import {ApexOptions} from "apexcharts";

export interface chartContent{
    name:string;
    data:number[];
}
export interface LineChartProps {
    series : chartContent[],
    categories:string[]
    title:string,
    unit:string,
    dataLabels?:boolean

}

const LineChart= (props:LineChartProps) => {
    const options = {
        series: props.series,
        options: {
            chart: {
                height: 350,
                type: 'line',
                zoom: {
                    enabled: true,
                },
            },
            dataLabels: {
                enabled: props.dataLabels || false,
            },
            stroke: {
                width: 2,
                curve: 'straight',
                dashArray: 0
            },
            title: {
                text: props.title,
                align: 'left'
            },
            legend: {
                tooltipHoverFormatter: function(val:any, opts:any) {
                    return val + ' - ' + opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] + ''
                }
            },
            markers: {
                size: 0,
                hover: {
                    sizeOffset: 6
                }
            },
            xaxis: {
                categories: props.categories,
            },
            tooltip: {
                y:
                    {
                        title: {
                            formatter: function (seriesName:string) {
                                return `${seriesName} (${props.unit}) -`;
                            }
                        }
                    }

            },
            grid: {
                borderColor: '#f1f1f1',
            }
        },


    };

    return (
        <Chart options={options.options as ApexOptions} series={options.series} width={520} height={350} />
    );
};

export default LineChart;