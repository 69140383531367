import React from 'react';
import {Button, Dropdown, MenuProps} from 'antd';
import {CalendarOutlined, FileImageOutlined, SortAscendingOutlined} from "@ant-design/icons";

interface sortBtnParam {
    setSortBy:(value : string)=>void;

}
const SortButton  = (props :sortBtnParam) => {

    const items:MenuProps['items'] = [
        {
            key: 'SORT_BY_REGDATE_DESC',
            label: '날짜: 최근 순',
            icon: <CalendarOutlined />,
        },
        {
            key: 'SORT_BY_REGDATE_ASC',
            label: '날짜: 오래된 순',
            icon: <CalendarOutlined />,
        },
        {
            key: 'SORT_BY_NAME_ASC',
            label: '파일명: 오름차순',
            icon: <FileImageOutlined />,
        },
        {
            key: 'SORT_BY_NAME_DESC',
            label: '파일명: 내림차순',
            icon: <FileImageOutlined />,
        },
    ];
    const handleMenuClick: MenuProps['onClick'] = (e) => {

        const key:string = e.key;
        props.setSortBy(key);

    };

    const menuProps = {
        items,
        onClick: handleMenuClick,
    };

    return (
        <Dropdown
            placement="bottomRight"
            menu={menuProps}
        >
            <Button type="primary" icon={<SortAscendingOutlined />} />
        </Dropdown  >
    );
};

export default SortButton;