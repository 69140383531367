import axiosInstance from "../../common/util/AxiosInstance";

export interface ExtentionData {
    extention:string;
    cnvtSupport:boolean;
    extentionStatus:boolean;
    makeThumb:boolean;

}

export async function  procGetExtentionData():Promise<ExtentionData[]>{

    const response = await axiosInstance.request<ExtentionData[]>(
        {
            method: "GET",
            url: `rest/docuwiz/admin/extension/select`,
            headers: {
                'Content-Type': 'application/json',
            },

        });

    return response.data;
};

export async function  procUpdateData(extentionData:ExtentionData):Promise<ExtentionData>{

    const response = await axiosInstance.request<ExtentionData>(
        {
            method: "POST",
            url: `rest/docuwiz/admin/extension/update`,
            headers: {
                'Content-Type': 'application/json',
            },
            data:{
                extentionData
            }

        });

    return response.data;
};

export async function  procDeleteCommentData(extentionData:ExtentionData):Promise<boolean>{

    const response = await axiosInstance.request<boolean>(
        {
            method: "DELETE",
            url: `rest/docuwiz/admin/extension/delete`,
            headers: {
                'Content-Type': 'application/json',
            },
            data:{
                extentionData
            }

        });

    return response.data;
};