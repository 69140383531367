import axios from 'axios';

import { CommonReturn } from "../types/CommonReturn";
import {JwtToken} from "../types/JwtToken";
import {Modal} from "antd";

// create an axios instance
const axiosInstance = axios.create({             // axios 인스턴스 생성
    baseURL: process.env.REACT_APP_API_BASE_URL || window.location.origin,
});


// 요청 인터셉터
axiosInstance.interceptors.request.use(
    (config) => {
        const token = sessionStorage.getItem("authorization");
        const url:string = config.url as string;
        if (url.startsWith('storagewiz')) {
            config.baseURL = process.env.REACT_APP_API_STORAGEWIZ_URL;
        }

        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// 응답 인터셉터
axiosInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {
        const originalRequest = error.config;

        if (error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;

            // 토큰이 없을때 재시도..
            const refreshToken = sessionStorage.getItem("refreshToken");
            if (!refreshToken) {
                return Promise.reject(error);
            }

            try {
                // 리플레시로 재인증
                const response = await axiosInstance.post<CommonReturn<JwtToken>>("rest/login/jwt/refresh", {
                    refreshToken,
                });

                //리플레시 인증 성공
                if(response.data.result)
                {
                    // 세션 스토리지에 토큰 저장
                    sessionStorage.setItem("authorization", response.data.data.token);
                    sessionStorage.setItem("refreshToken", response.data.data.refreshToken);
                    originalRequest.headers.Authorization = `Bearer ${response.data.data.token}`;
                    // 인증받고 재시도..
                    return axiosInstance(originalRequest);
                }
                else
                {
                    //리플레시 인증 실패
                    Modal.error({
                        title: '인증 오류',
                        content: '유효세션이 만료되었습니다(토큰 재발급 실패) 다시 접속해주세요,',
                    });
                    return Promise.reject(error);
                }

            } catch (error) {
                Modal.error({
                    title: '인증 오류',
                    content: '유효세션이 만료되었습니다. 다시 접속해주세요,',
                });
                return Promise.reject(error);
            }
        }

        if(error.response.data.data)
        {
            Modal.error({
                title: error.response.data.data ,
                content: error.response.data.message,
            });
        }
        else
        {
            Modal.error({
                title: error.response.status + ' : ' +error.message,
                content: '예기치않은 오류가 발생했습니다. 반복되면 관리자에게 문의하세요.',
            });
        }

        return Promise.reject(error);
    }
);

export default axiosInstance;