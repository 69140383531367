import React, { createContext, Key } from "react";

export interface DocuWizViewerContextType {
    checkedItem: Array<Key>|null;
    setCheckedItem: (value: Array<Key>|null) => void;
    listMode: string|null;
    setListMode: (value: string|null) => void;
    allSelected: boolean|null;
    setAllSelected: (value: boolean|null) => void;
    showDeleteBox: boolean|null;
    setShowDeleteBox: (value: boolean|null) => void;
    indeterminate: boolean|null;
    setIndeterminate: (value: boolean|null) => void;
    sortBy: string|null;
    setSortBy: (value: string|null) => void;
}

const DocuWizViewerContextValue: DocuWizViewerContextType = {
    checkedItem: [],
    setCheckedItem: () => {},
    listMode: null,
    setListMode: () => {},
    allSelected: false,
    setAllSelected: () => {},
    showDeleteBox: false,
    setShowDeleteBox: () => {},
    indeterminate: false,
    setIndeterminate: () => {},
    sortBy: null,
    setSortBy: () => {},
};

const DocuWizViewerContext = createContext<DocuWizViewerContextType>(
    DocuWizViewerContextValue
);
export default DocuWizViewerContext;