import React, {ChangeEvent, useCallback, useContext, useEffect, useRef, useState} from "react";
import "./css/DocuwizViewer.scss";
import {QueryKey, useQueryClient} from "@tanstack/react-query";
import {
    useCommonUseMutation,
    useCommonUseQuery
} from "../../../../../common/hooks/rest/ReacQueryHook";

import FileListCompnt from "../FileListViewer/FileListCompnt";
import ThumbListCompnt from "../ThumbListViewer/ThumbListCompnt";
import {message, Modal, theme} from "antd";
import LoadingContainer from "../../../../../common/component/LoadingContainer/LoadingContainer";
import {DeleteOption} from "../data/DeleteOption";
import ViewerOptionContext from "../../../../../context/ViewerOptionContext";
import {ViewerOption} from "../../../../../data/ViewerOption";
import {DocumentList} from "../data/DocumentList";
import DocuwizViewerContext from "../../../../../context/DocuwizViewerContext";
import VersionMgrModal from "../VersionMgr/VersionMgrModal";
import ViewerDetailContext, {ViewerDetailContextType} from "../../../../../context/ViewerDetailContext";
import SlideDrawer from "../SlideDrawer/SlideDrawer";
import {procDeleteFile, procSelectFile, procUploadFile} from "../../../../../rest/docuwizViewer/ProcFileRest";
import {fileSizeToBytes} from "../../../../../common/util/FileUtil";
import useModal from "../../../../../common/hooks/useModal";

function DocuwizViewer() {

    const { viewerOption } = useContext(ViewerOptionContext);

    const docuwizContext = useContext(DocuwizViewerContext);
    const {showDeleteBox, setShowDeleteBox, checkedItem,sortBy
    ,listMode} = docuwizContext;

    const dragRef = useRef<HTMLDivElement | null>(null);
    const isDraggable:boolean = viewerOption?.mode as string === "rw";
    const viwerRef = useRef<HTMLDivElement>(null);

    const [isDragging, setIsDragging] = useState<boolean>(false);
    const [isUploading, setUpLoading] = useState<boolean>(false);

    const { openModal } = useModal();

    const initialDocuWizOpt: ViewerDetailContextType = {
        showSideSlide: false,
        setShowSideSlide: () => {},
        fileInfoData:null,
        setFileInfoData: () => {},
        docId:null,
        setDocId:() => {},
        showVersion: false,
        setShowVersion: () => {},
        showComment: false,
        setShowComment: () => {},
        refetchViewer: false,
        setRefetchViewer: () => {},
    };

    const [viewDetailOpt, setViewDetailOpt] = useState<ViewerDetailContextType>(initialDocuWizOpt);

    //리액트 쿼리 키
    const queryKey:QueryKey =   [viewerOption?.interfaceId];

    const requestParam:ViewerOption = {
        encryptInterfaceId: viewerOption?.encryptInterfaceId as string,
        encryptInterfaceMode: viewerOption?.encryptInterfaceMode as string,
        requestPageSize:viewerOption?.requestPageSize as number,
        mode :viewerOption?.mode as string,
        interfaceId : viewerOption?.interfaceId as string,
        sortBy:sortBy as string,
    }

    //조회로직
    const { data, isLoading, isFetching,refetch } = useCommonUseQuery<DocumentList[]>(
        queryKey,
        () =>procSelectFile(requestParam));

    //업로드로직
    const queryClient = useQueryClient();

    const mutateUpload = useCommonUseMutation<DocumentList[]>(queryKey, queryClient, procUploadFile);
    const mutateDelete = useCommonUseMutation<DocumentList[]>(queryKey, queryClient, procDeleteFile);


    const deleteFiles =  () =>
    {
        Modal.confirm({
            title: '선택한 파일을 삭제하시겠습니까?',
            content: "삭제 후에는 복구되지 않습니다.",
            open: showDeleteBox as boolean,
            okText: "확인",
            cancelText: "취소",
            async onOk() {
                setShowDeleteBox(false);
                try {
                    const deleteData:DeleteOption = {
                        encryptInterfaceId:viewerOption?.encryptInterfaceId as string,
                        deleteDocIds:checkedItem as React.Key[],
                    }

                    await mutateDelete.mutateAsync(deleteData);
                    message.success('선택한 파일을 삭제했습니다.');
                } catch (error) {
                    console.error('delete File failed:', error);
                } finally {
                    setUpLoading(false);
                }


            },
            onCancel() {
                setShowDeleteBox(false);
            },
        });
    }

    const deleteFile =  (fileName:string, docId:string) =>
    {
        Modal.confirm({
            title: `${fileName}을(를) 삭제하시겠습니까?`,
            content: "삭제 후에는 복구되지 않습니다.",
            open: showDeleteBox as boolean,
            okText: "확인",
            cancelText: "취소",
            async onOk() {
                setShowDeleteBox(false);
                try {
                    const deleteData:DeleteOption = {
                        encryptInterfaceId:viewerOption?.encryptInterfaceId as string,
                        deleteDocIds:[docId],
                    }

                    await mutateDelete.mutateAsync(deleteData);
                    message.success(`${fileName}을(를) 삭제했습니다.`);
                } catch (error) {
                    console.error(`${fileName}을(를) 삭제 실패했습니다.:`, error);
                } finally {
                    setUpLoading(false);
                }


            },
            onCancel() {
                setShowDeleteBox(false);
            },
        });
    }


    const onChangeFiles = useCallback(
        (e: ChangeEvent<HTMLInputElement> | any): void =>
        {
            setUpLoading(true);
            let selectFiles: File[];

            if (e.type === "drop") {
                selectFiles = e.dataTransfer.files;
            } else {
                selectFiles = e.target.files;
            }

            fileUpload(selectFiles);


        },
        []
    );

    const fileUpload = async (selectFiles: File[]) => {

        let formData = new FormData(); // formData 객체를 생성한다.
        let isFile: boolean = false;
        let isError:boolean = false;
        const strLimitSize:string = viewerOption?.limitSize || '50MB';
        const limitSize:number = fileSizeToBytes(strLimitSize);
        for (const file of selectFiles) {
            // Check if the file is not a file
            if (!(file instanceof File)) continue;

            // Check the file extension
            const fileExtension = file.name.split('.').pop() || '';
            if (viewerOption?.limitExtension && !viewerOption?.limitExtension.includes(fileExtension)) {
                openModal({ title: '허용하지 않는 확장자의 파일이 포함되어 있습니다.', content: `${file.name}\n허용 확장자: ${viewerOption?.limitExtension.join(', ')}`, type:'error' });
                isError = true;
                break;
            }

            // Check the file size
            if (viewerOption?.limitSize && file.size > limitSize) {
                openModal({ title: '허용하는 파일 용량을 초과했습니다.', content: `${file.name}\n허용용량: ${strLimitSize}`, type:'error' });
                isError = true;
                break;
            }

            isFile = true;
            formData.append("uploadfile", file);
        }

        if (isError)
        {
            setUpLoading(false);
            return;
        }
        if (!isFile) {
            setUpLoading(false);
            return;
        }

        const requestData = {
            interfaceId: viewerOption?.encryptInterfaceId,
            userId: sessionStorage.getItem("user_id"),
        };
        formData.append('requestData', new Blob([JSON.stringify(requestData)], { type: 'application/json' }));

        try {
            await mutateUpload.mutateAsync(formData);
            message.success('파일 업로드를 성공했습니다.');
        } catch (error) {
            console.error('파일 업로드를 실패했습니다.:', error);
        } finally {
            setUpLoading(false);
        }
    }

    const handleDragIn = useCallback((e: DragEvent): void => {
        e.preventDefault();
        e.stopPropagation();
    }, []);

    const handleDragOut = useCallback((e: DragEvent): void => {
        e.preventDefault();
        e.stopPropagation();

        setIsDragging(false);
    }, []);

    const handleDragOver = useCallback((e: DragEvent): void => {
        e.preventDefault();
        e.stopPropagation();

        if (e.dataTransfer!.files) {
            setIsDragging(true);
        }
    }, []);

    const handleDrop = useCallback(
        (e: DragEvent): void => {
            e.preventDefault();
            e.stopPropagation();

            onChangeFiles(e);
            setIsDragging(false);
        },
        [onChangeFiles]
    );

    const initDragEvents = useCallback((): void => {
        if (dragRef.current !== null) {
            dragRef.current.addEventListener("dragenter", handleDragIn);
            dragRef.current.addEventListener("dragleave", handleDragOut);
            dragRef.current.addEventListener("dragover", handleDragOver);
            dragRef.current.addEventListener("drop", handleDrop);
        }
    }, [handleDragIn, handleDragOut, handleDragOver, handleDrop]);

    const resetDragEvents = useCallback((): void => {
        if (dragRef.current !== null) {
            dragRef.current.removeEventListener("dragenter", handleDragIn);
            dragRef.current.removeEventListener("dragleave", handleDragOut);
            dragRef.current.removeEventListener("dragover", handleDragOver);
            dragRef.current.removeEventListener("drop", handleDrop);
        }
    }, [handleDragIn, handleDragOut, handleDragOver, handleDrop]);

    const {
        token: { colorBgContainer },
    } = theme.useToken();


    useEffect(() => {
        initDragEvents();
         return () => { // cleanup
            resetDragEvents();
        }

    }, [initDragEvents, resetDragEvents]);


    useEffect( ()=>{
        if (showDeleteBox)
        {
            deleteFiles();
        }

    }, [showDeleteBox])

    useEffect(() => {
        //조회 sort
        if (data) {
            queryClient.invalidateQueries(queryKey).then();
            refetch();
        }

    }, [sortBy]);

    useEffect(() => {
        if(data && viewDetailOpt.refetchViewer)
        {
            const newViewDetailOpt = { ...viewDetailOpt, refetchViewer: false };
            // Set the new object as the new state
            setViewDetailOpt(newViewDetailOpt);
            queryClient.invalidateQueries(queryKey).then();
            refetch();
        }
    }, [viewDetailOpt.refetchViewer]);

    const style = {
        borderRadius: '8px',
        background: colorBgContainer,
    }

    return (
        <div
            style={{...style, width:"100%", position: 'relative', overflowX:'hidden', overflowY:'hidden' }}
            className={isDragging ? "DragDrop-File-Dragging" : "DragDrop-File"}
            ref={isDraggable ? dragRef : null}
        >
            <input
                type="file"
                id="uploadfile"
                name="uploadfile"
                style={{ display: "none" }}
                multiple={true}
                onChange={onChangeFiles}
            />

            <input
                type="file"
                id="uploadfolder"
                name="uploadfolder"
                style={{ display: "none" }}
                multiple={true}
                onChange={onChangeFiles}
                // @ts-ignore
                webkitdirectory=""
                // @ts-ignore
                directory=""
            />
                {
                    isUploading && (
                    <LoadingContainer message={"문서를 업로드 중입니다."}/>
                    )
                }
                {
                    (isLoading || isFetching) && (
                    <LoadingContainer message={"문서를 불러오는 중입니다."}/>
                    )
                }
            <ViewerDetailContext.Provider
                value={{
                    fileInfoData: viewDetailOpt.fileInfoData,
                    setFileInfoData: (value) => setViewDetailOpt((preViewDetailOpt) => ({ ...preViewDetailOpt, fileInfoData: value })),
                    showSideSlide:viewDetailOpt.showSideSlide,
                    setShowSideSlide:(value) => setViewDetailOpt((preViewDetailOpt) => ({ ...preViewDetailOpt, showSideSlide: value })),
                    showVersion:viewDetailOpt.showVersion,
                    setShowVersion:(value) => setViewDetailOpt((preViewDetailOpt) => ({ ...preViewDetailOpt, showVersion: value })),
                    docId:viewDetailOpt.docId,
                    setDocId:(value) => setViewDetailOpt((preViewDetailOpt) => ({ ...preViewDetailOpt, docId: value })),
                    showComment:viewDetailOpt.showComment,
                    setShowComment:(value) => setViewDetailOpt((preViewDetailOpt) => ({ ...preViewDetailOpt, showComment: value })),
                    refetchViewer: viewDetailOpt.refetchViewer,
                    setRefetchViewer: (value) => setViewDetailOpt((preViewDetailOpt) => ({ ...preViewDetailOpt, refetchViewer: value })),
                }}
            >
                {
                    data && (

                    listMode === "File" ?

                        <FileListCompnt
                            isLoading = {isLoading}
                            flattenedData={data}
                            viwerRef={viwerRef}
                            onDeleteFile={deleteFile}
                        />
                    :
                        <ThumbListCompnt
                            isLoading = {isLoading}
                            flattenedData={data}
                            viwerRef={viwerRef}
                            onDeleteFile={deleteFile}
                        />
                    )
                }
                <SlideDrawer />
                <VersionMgrModal/>
            </ViewerDetailContext.Provider>
        </div>
    );
}

export default DocuwizViewer;