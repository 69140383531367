import React from 'react';
import {Button, Checkbox, Space, Table, Tag, theme, Tooltip} from "antd";
import {ColumnsType} from "antd/es/table";
import {ExtentionData, procGetExtentionData} from "../../../rest/admin/ExtentionMgr";
import {QueryKey, useQueryClient} from "@tanstack/react-query";
import {useCommonUseQuery} from "../../../common/hooks/rest/ReacQueryHook";
import {getFileColor} from "../../../common/util/ColorUtil";
import Search from "antd/es/input/Search";
const FileMgrExtention: React.FC = () => {
    const { token: { colorBgContainer },} = theme.useToken();
    const queryClient = useQueryClient();
    const queryKey:QueryKey =   [`extentionMgr`];
    const { data} = useCommonUseQuery<ExtentionData[]>(
        queryKey,
        () =>procGetExtentionData());

    const onSearch = (value: string) => console.log(value);


    const columns: ColumnsType<ExtentionData> = [
        {
            title: '허용확장자',
            dataIndex: 'extention',
            key: 'extention',
            render: (text: string, record:ExtentionData) =>
                <div style={{ paddingBottom: '9px', margin:'0', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Tag color={getFileColor(text)} key={text.toUpperCase()}>
                        {text.toUpperCase()}
                    </Tag>

                </div>
            ,
        },
        {
            title: '파일변환여부',
            dataIndex: 'cnvtSupport',
            key: 'cnvtSupport',
            render: (text: boolean,record:ExtentionData) =>
                <div style={{ paddingBottom: '9px', margin:'0', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Checkbox checked={record.cnvtSupport || false} />
                </div>
            ,
        },
        {
            title: '활성화여부',
            dataIndex: 'extentionStatus',
            key: 'extentionStatus',
            render: (text: boolean, record:ExtentionData) =>
                <div style={{ paddingBottom: '9px', margin:'0', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Checkbox checked={record.extentionStatus || false} />
                </div>
            ,
        },
        {
            title: '썸네일생성여부',
            key: 'makeThumb',
            dataIndex: 'makeThumb',
            render: (text: boolean, record:ExtentionData) =>
                <div style={{ paddingBottom: '9px', margin:'0', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Checkbox checked={record.makeThumb || false} />
                </div>
            ,
        },
        {
            title: '',
            key: 'Active',
            dataIndex: 'Active',
            render: (text, record:ExtentionData) =>
                <div style={{ paddingBottom: '9px', margin:'0', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Button type="link" size="small">삭제</Button>
                </div>
            ,
        },
    ];

    return (
        <>
        <nav style={{
            margin:'0px 0px 14px 0px',
            padding:'9px 8px 9px 0px',
            height: '50px',
            display:'flex',
            justifyContent: "space-between",
            alignItems:'center',
            borderRadius: "8px", // 라운드 처리 추가
            border: '1px solid #dadada',
            background: colorBgContainer,
        }}>
            <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{
                    padding: '9px 5px',
                    display:'flex',
                }}>
                    <Search placeholder="확장자를 검색해보세요." allowClear onSearch={onSearch} style={{ width: 210 }} />
                </div>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
                <Button type="primary" size="small">확장자 추가</Button>
            </div>
        </nav>
        <Table columns={columns}
               dataSource={data}
               rowKey="extention"
        />
        </>
    );
};

export default FileMgrExtention;
