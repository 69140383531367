import React, {useState} from 'react';
import {Button, Table, theme} from "antd";
import {ColumnsType} from "antd/es/table";
import {QueryKey, useQueryClient} from "@tanstack/react-query";
import {useCommonUseQuery} from "../../../common/hooks/rest/ReacQueryHook";
import Search from "antd/es/input/Search";
import {formatDate} from "../../../common/util/DateUtil";
import UserAvatar from "../../index/header/UserAvatar/UserAvatar";
import {procGetUserList, UserData} from "../../../rest/admin/UserMgr";
import {procGetAesMgrList, TB_SECURITY_AES} from "../../../rest/admin/SecurityAesMgr";
import {procGetJwtMgrList, TB_SECURITY_JWT} from "../../../rest/admin/SecurityJwtMgr";

const JwtMgr: React.FC = () => {
    const { token: { colorBgContainer },} = theme.useToken();
       const queryClient = useQueryClient();
    const queryKey:QueryKey =   [`JwtMgr`];
    const { data} = useCommonUseQuery<TB_SECURITY_JWT[]>(
        queryKey,
        () =>procGetJwtMgrList());


    const columns: ColumnsType<TB_SECURITY_JWT> = [
        {
            title: '버전',
            dataIndex: 'jwtKeyVer',
            key: 'jwtKeyVer',
            render: (text: string) =>
                <div style={{ paddingBottom: '9px', margin:'0', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    {text}.0
                </div>
            ,
        },
        {
            title: '로그인 암호화키',
            dataIndex: 'jwtKey',
            key: 'jwtKey',
            render: (text: string) =>
                <div style={{ paddingBottom: '9px', margin:'0', display: 'flex', alignItems: 'left', justifyContent: 'left' }}>
                    {text}
                </div>
            ,
        },
        {
            title: '로그인 활성화시간',
            dataIndex: 'jtwExpireMs',
            key: 'jtwExpireMs',
            render: (text: string) =>
                <div style={{ paddingBottom: '9px', margin:'0', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    {text} sec
                </div>
            ,
        },

        {
            title: 'JWT 생성일자',
            key: 'lastRegTime',
            dataIndex: 'lastRegTime',
            render: (text: string) =>
                <div style={{ paddingBottom: '9px', margin:'0', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    {formatDate(text)}
                </div>
            ,
        },

    ];

    return (
        <>
        <nav style={{
            margin:'0px 0px 14px 0px',
            padding:'9px 8px 9px 0px',
            height: '50px',
            display:'flex',
            justifyContent: "space-between",
            alignItems:'center',
            borderRadius: "8px", // 라운드 처리 추가
            border: '1px solid #dadada',
            background: colorBgContainer,
        }}>
            <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{
                    padding: '9px 5px',
                    display:'flex',
                }}>
                </div>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
                <Button type="primary" size="small">암호갱신</Button>
            </div>
        </nav>
        <Table columns={columns}
               dataSource={data}
               rowKey="jwtKey"
        />
        </>
    );
};

export default JwtMgr;
