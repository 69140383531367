import React from 'react';
import {Button, Space, message, Input, Tooltip} from 'antd';
import {CopyOutlined} from "@ant-design/icons";

interface ClipbordCopy {
    copyString:string,

}
const CopyToClipboard = (props :ClipbordCopy) => {

    const textToCopy = props.copyString;

    const handleCopyClick = async () => {
        try {
            await navigator.clipboard.writeText(textToCopy);
            message.success(`복사 되었습니다.`);
        } catch (err) {
            message.error(`복사에 실패했습니다.`);
        }
    };

    return (
        <Space.Compact>
            <Input style ={{width:'155px'}} readOnly={true} defaultValue={textToCopy} />
            <Tooltip title={`복사`} arrow={false}>
                <Button onClick={handleCopyClick} type="primary" icon={<CopyOutlined />}/>
            </Tooltip>
        </Space.Compact>
    );
};

export default CopyToClipboard;