import axiosInstance from "../../common/util/AxiosInstance";

export interface UserData {
    burkCd:string|null;
    burkName:string|null;
    deptCD:string|null;
    deptName:string|null;
    userId:string|null;
    userName:string|null;
    createTime:string|null;
    updateTime:string|null;
    userStatus:boolean|null;
}

export async function  procGetUserList(userOpt:UserData):Promise<UserData[]>{

    const response = await axiosInstance.request<UserData[]>(
        {
            method: "GET",
            url: `rest/docuwiz/admin/user/select`,
            headers: {
                'Content-Type': 'application/json',
            },
            params:userOpt,
        });

    return response.data;
};