import React from 'react';
import { Row, Col } from 'antd';
import LineChart, {chartContent} from "../common/LineChart";
const FileMgrDashBoard: React.FC = () => {
    const getColProps = () => {
        const baseProps = {xs: 24, sm: 12, md: 12, lg: 12, xl: 12, xxl: 12};
        return baseProps;
    }

    function getRandomNumberInRange(min: number, max: number): number {
        if (min > max) {
            throw new Error('Min value should not be greater than Max value');
        }
        return Math.floor(Math.random() * (max - min + 1) + min);
    }

    const todayCategory = [
                                    '00','01','02','03','04','05',
                                    '06','07','08','09','10','11',
                                    '12','13','14','15','16','17',
                                    '18','19','20','21','22','23',
                                    ]

    const todaySeries:chartContent[] = [
                                        {
                                            name: "오늘",
                                            data: [
                                                5, 0, 0, 0, 0, 0,
                                                0, 0, 1, 3, 10, 30,
                                                0, 6, 40, 20, 30, 10,
                                                5, 3, 5, 1, 0, 0,
                                            ]
                                        },
                                    ]

    const monthCategory = [
        '01','02','03','04','05','06',
        '07','08','09','10','11','12',
        '13','14','15','16','17','18',
        '19','20','21','22','23','24',
        '25','26','27','28','29','30','31',
    ]

    const monthSeries:chartContent[] = [
        {
            name: "이번달",
            data: [
                1, 2, 4, 0.5, 1, 0.1,
                0.1, 0.1, 1.3, 0.1, 0, 0,
                0, 0.1, 0.1, 0.2, 0.5, 0.1,
                1, 3.5, 2.1, 1, 1.2, 3,
                1.2, 1.3, 2.1, 1.3, 1.2, 2.3, 1.2
            ]
        },
    ]

    const yearCategory = [
        '01','02','03','04',
        '05','06','07','08',
        '09','10','11','12',
    ]

    const yearSeries:chartContent[] = [
        {
            name: "2019년",
            data: [
                getRandomNumberInRange(18,32), getRandomNumberInRange(18,32), getRandomNumberInRange(18,32), getRandomNumberInRange(18,32),
                getRandomNumberInRange(18,32), getRandomNumberInRange(18,32), getRandomNumberInRange(18,32), getRandomNumberInRange(18,32),
                getRandomNumberInRange(18,32), getRandomNumberInRange(18,32), getRandomNumberInRange(18,32), getRandomNumberInRange(18,32)
            ],
        },
        {
            name: "2020년",
            data: [
                getRandomNumberInRange(18,32), getRandomNumberInRange(18,32), getRandomNumberInRange(18,32), getRandomNumberInRange(18,32),
                getRandomNumberInRange(18,32), getRandomNumberInRange(18,32), getRandomNumberInRange(18,32), getRandomNumberInRange(18,32),
                getRandomNumberInRange(18,32), getRandomNumberInRange(18,32), getRandomNumberInRange(18,32), getRandomNumberInRange(18,32)
            ],
        },
        {
            name: "2021년",
            data: [
                getRandomNumberInRange(18,32), getRandomNumberInRange(18,32), getRandomNumberInRange(18,32), getRandomNumberInRange(18,32),
                getRandomNumberInRange(18,32), getRandomNumberInRange(18,32), getRandomNumberInRange(18,32), getRandomNumberInRange(18,32),
                getRandomNumberInRange(18,32), getRandomNumberInRange(18,32), getRandomNumberInRange(18,32), getRandomNumberInRange(18,32)
            ],
        },
        {
            name: "2022년",
            data: [
                getRandomNumberInRange(18,32), getRandomNumberInRange(18,32), getRandomNumberInRange(18,32), getRandomNumberInRange(18,32),
                getRandomNumberInRange(18,32), getRandomNumberInRange(18,32), getRandomNumberInRange(18,32), getRandomNumberInRange(18,32),
                getRandomNumberInRange(18,32), getRandomNumberInRange(18,32), getRandomNumberInRange(18,32), getRandomNumberInRange(18,32)
            ],
        },
        {
            name: "2023년",
            data: [
                13, 17, 16, 15,
                23, 0, 0, 0,
                0, 0, 0, 0
            ],
        },
    ]

    const quarterCategory = [
        '1/4','2/4','3/4','4/4',
    ]

    const quarterSeries:chartContent[] = [
        {
            name: "2022년",
            data: [
                80, 91, 78, 101
            ],
        },
        {
            name: "2023년",
            data: [
                84, 88, 88, 110
            ],
        },
    ]

    return (
        <Row gutter={[16,16]}>
            <Col {...getColProps()}>
                <LineChart title="오늘업로드 현황 (건별)" key={1} unit="건" categories={todayCategory} series={todaySeries} dataLabels={true}/>
            </Col>
            <Col {...getColProps()}>
                <LineChart title="이번달 업로드 현황 (용량-GB)" key={2} unit="GB" categories={monthCategory} series={monthSeries} dataLabels={true}/>
            </Col>
            <Col {...getColProps()}>
                <LineChart title="분기별 업로드 현황 (용량-GB)" key={3} unit="GB" categories={quarterCategory} series={quarterSeries} dataLabels={true}/>
            </Col>
            <Col {...getColProps()}>
                <LineChart title="연도별 업로드 현황 - 최근5년 (용량-GB)" key={4} unit="GB" categories={yearCategory} series={yearSeries} dataLabels={true}/>
            </Col>
        </Row>
    );
};

export default FileMgrDashBoard;
