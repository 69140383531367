import axiosInstance from "../../common/util/AxiosInstance";

export interface TB_SECURITY_JWT {
    jwtKeyVer:string,
    jwtKey:string,
    jtwExpireMs:string,
    lastRegTime:string,
}

export async function  procGetJwtMgrList():Promise<TB_SECURITY_JWT[]>{

    const response = await axiosInstance.request<TB_SECURITY_JWT[]>(
        {
            method: "GET",
            url: `rest/docuwiz/admin/security/jwt/select`,
            headers: {
                'Content-Type': 'application/json',
            },
        });

    return response.data;
};