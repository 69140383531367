import React, { useState, useEffect } from 'react';
import {
    AreaChartOutlined, CloudOutlined, CloudServerOutlined, DashboardOutlined,
    DatabaseOutlined, EyeOutlined,
    FileOutlined, FileZipOutlined, FolderViewOutlined, HistoryOutlined,
    MenuFoldOutlined,
    MenuUnfoldOutlined,  RestOutlined, SaveOutlined, SecurityScanOutlined, TeamOutlined,
    UserOutlined,
    VideoCameraOutlined,
} from '@ant-design/icons';
import {Layout, Menu, Button, theme, Breadcrumb, Typography} from 'antd';
import RenderComponent from "../../component/admin/Nav/RenderComponent";

const { Sider, Content } = Layout;

const Admin: React.FC = () => {
    const { Title } = Typography;

    const logoUrl =  process.env.REACT_APP_API_BASE_URL as string+  process.env.REACT_APP_LOGO_URL as string;

    const [collapsed, setCollapsed] = useState(window.innerWidth <= 768);
    const {
        token: { colorBgContainer },
    } = theme.useToken();

    const [selectedKey, setSelectedKey] = useState<string>('파일관리/현황');

    const handleMenuClick = (e:any) => {
        setSelectedKey(e.key);
    };

    const openParentMenu:string[] =collapsed ? [] : ['파일관리', '사용자관리', '모니터링', ];
    const menuItems = [
                                                    {
                                                        key: '파일관리',
                                                        icon: <FileOutlined />,
                                                        label: '파일관리',
                                                        children: [
                                                            {
                                                                key: '파일관리/현황',
                                                                icon: <AreaChartOutlined />,
                                                                label: '현황',
                                                            },
                                                            {
                                                                key: '파일관리/확장자관리',
                                                                icon: <FolderViewOutlined />,
                                                                label: '확장자관리',
                                                            },
                                                            {
                                                                key: '파일관리/문서관리',
                                                                icon: <FileOutlined />,
                                                                label: '문서관리',
                                                            },
                                                        ]
                                                    },
                                                    {
                                                        key: '사용자관리',
                                                        icon: <UserOutlined />,
                                                        label: '사용자관리',
                                                        children: [
                                                            {
                                                                key: '사용자관리/현황',
                                                                icon: <AreaChartOutlined />,
                                                                label: '현황',
                                                            },
                                                            {
                                                                key: '사용자관리/권한관리',
                                                                icon: <TeamOutlined />,
                                                                label: '권한관리',
                                                            },
                                                        ]
                                                    },
                                                    {
                                                        key: '모니터링',
                                                        icon: <EyeOutlined />,
                                                        label: '모니터링',
                                                        children: [
                                                            {
                                                                key: '모니터링/DB 트랜젝션',
                                                                icon: <HistoryOutlined />,
                                                                label: 'DB 트랜젝션',
                                                            },
                                                            {
                                                                key: '모니터링/파일 트랜젝션',
                                                                icon: <SaveOutlined />,
                                                                label: '파일 트랜젝션',
                                                            },
                                                            {
                                                                key: '모니터링/SAP RFC',
                                                                icon: <DashboardOutlined />,
                                                                label: 'SAP RFC',
                                                            },
                                                            {
                                                                key: '모니터링/RCMS',
                                                                icon: <DatabaseOutlined />,
                                                                label: 'RCMS',
                                                            },
                                                            {
                                                                key: '모니터링/EZBARO',
                                                                icon: <DatabaseOutlined />,
                                                                label: 'EZBARO',
                                                            },
                                                        ]
                                                    },
                                                    {
                                                        key: '보안관리',
                                                        icon: <VideoCameraOutlined />,
                                                        label: '보안관리',
                                                        children: [
                                                            {
                                                                key: '보안관리/파일암호화',
                                                                icon: <FileZipOutlined />,
                                                                label: '파일암호화',
                                                            },
                                                            {
                                                                key: '보안관리/로그인토큰',
                                                                icon: <SecurityScanOutlined />,
                                                                label: '로그인토큰',
                                                            },
                                                        ]
                                                    },
                                                    {
                                                        key: '스토리지관리',
                                                        icon: <CloudOutlined />,
                                                        label: '스토리지관리',
                                                        children: [
                                                            {
                                                                key: '스토리지관리/경로설정',
                                                                icon: <DatabaseOutlined />,
                                                                label: '경로설정',
                                                            },
                                                            {
                                                                key: '스토리지관리/인프라설정',
                                                                icon: <CloudServerOutlined />,
                                                                label: '인프라설정',
                                                            },
                                                            {
                                                                key: '스토리지관리/RESTAPI설정',
                                                                icon: <RestOutlined />,
                                                                label: 'RESTAPI설정',
                                                            },
                                                        ]
                                                    },

                                                ]

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 768) {
                setCollapsed(true);
            } else {
                setCollapsed(false);
            }
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <Layout style={{ height: '100vh' }}>
            <Sider trigger={null} collapsible collapsed={collapsed} style={{ background: colorBgContainer }}>
                <div style={{ height: 64, display: 'flex', alignItems: 'center', justifyContent: collapsed ? 'center' : 'flex-end' }}>
                    <Button
                        type="text"
                        icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                        onClick={() => setCollapsed(!collapsed)}
                        style={{ fontSize: '16px', width: 64, height: 64 }}
                    />
                </div>
                {!collapsed && (
                    <div style={{ height: 64, display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 16, marginLeft : 6, marginRight:6, border:'1px solid lightgray', borderRadius:5 }}>
                        <img src={logoUrl} style={{ height: '80%', width: 'auto' }}/>
                    </div>
                )}

                <div style={{ overflow: 'auto', height: 'calc(100vh - 144px)', marginTop: '16px' }}>
                    <Menu
                        mode="inline"
                        defaultSelectedKeys={['파일관리/현황']}
                        defaultOpenKeys={openParentMenu}
                        onClick={handleMenuClick}
                        items={menuItems}
                    />
                </div>
            </Sider>

            <Layout>
                <Breadcrumb style={{ margin: '16px 16px' }}>
                    <Breadcrumb.Item>{selectedKey.split('/')[0]}</Breadcrumb.Item>
                    <Breadcrumb.Item>{selectedKey.split('/')[1]}</Breadcrumb.Item>
                </Breadcrumb>
                <Content
                    style={{
                        marginLeft:15,
                        marginRight:15,
                        marginBottom:15,
                        padding: 8,
                        minHeight: 280,
                        background: colorBgContainer,
                        borderRadius:10,
                        flex: 1,
                        overflowY:'auto',
                    }}
                >
                    <Title style={{margin:10}} level={4}>{selectedKey.split('/')[1]}</Title>
                    <RenderComponent menuKey={selectedKey} />
                </Content>
            </Layout>
        </Layout>
    );
};

export default Admin;
