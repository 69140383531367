export function stringToColor (string: string): string {
    let hash = Math.floor(Math.random() * 1000);
    let i;
    const maxColorValue = 230; // 어두운 색상 위주로 만들기 위해 최대 색상 값을 줄입니다. (0-255 범위 내에서 선택)

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
        const value = ((hash >> (i * 8)) & 0xff) % (maxColorValue + 1);
        color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
};


export function getFileColor (extension: string): string {

    const colors: { [key: string]: string } = {
        // 문서 파일 확장자 색상 코드
        'doc': '#2B579A',
        'docx': '#2B579A',
        'xls': '#217346',
        'xlsx': '#217346',
        'ppt': '#B7472A',
        'pptx': '#B7472A',
        'pdf': '#E34527',
        'txt': '#000000',
        'hwp': '#25A5EC',
        'hwpx': '#25A5EC',
        // 이미지 파일 확장자 색상 코드
        'jpg': '#4CAF50',
        'jpeg': '#4CAF50',
        'png': '#FF5722',
        'gif': '#9C27B0',
        'svg': '#E91E63',
        'bmp': '#2196F3',
    };

    return colors[extension.toLowerCase()] || stringToColor(extension);
};
