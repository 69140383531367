import { BrowserRouter, Routes, Route } from 'react-router-dom';
import React from "react";
import PrivateRoute from "../common/component/PrivateRoute/PrivateRoute";
import ErrPage from "../common/pages/ErrPage";
import LoginPage from "../pages/cert/LoginPage";
import DocuwizLayout from "../pages/index/DocuwizLayout";
import DocuwizPopupLayout from "../pages/view/DocuwizPopupLayout";
import Admin from "../pages/admin/Admin";


function DocuWizRouter() {

     return (
            <BrowserRouter>
                <Routes>
                    <Route element={<PrivateRoute authentication={true}/>}>
                        <Route path="/:encryptInterfaceId/:encryptMode" element={<DocuwizLayout/>} />
                    </Route>
                    <Route element={<PrivateRoute authentication={false}/>}>
                        <Route path="/cert/:param" element={<LoginPage/>} />
                    </Route>
                    <Route element={<PrivateRoute authentication={true}/>}>
                        <Route path="/view/:encryptInterfaceId/:encryptDocId" element={<DocuwizPopupLayout/>} />
                    </Route>
                    <Route element={<PrivateRoute authentication={false}/>}>
                        <Route path="/admin" element={<Admin/>} />
                    </Route>

                    {/* 상단에 위치하는 라우트들의 규칙을 모두 확인, 일치하는 라우트가 없는경우 처리 ddd*/}
                    <Route path="*" element={<ErrPage errCode={9999} />}></Route>
                </Routes>
            </BrowserRouter>
    );
}
export default DocuWizRouter;