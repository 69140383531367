import React, { useState, useEffect } from "react";

interface responseParam<T> {
    result:boolean,
    message:string,
    data:T
}

function useWebSocketApi<T>(url: string, shouldConnect: boolean) {
    const [data, setData] = useState<T | null>(null);

    useEffect(() => {
        if (!shouldConnect) {
            return;
        }

        const webSocket = new WebSocket(url);

        webSocket.onopen = () => {
            console.log("WebSocket connection is open");
        };

        webSocket.onerror = (event: Event) => {
            console.error("WebSocket connection error");
        };

        webSocket.onmessage = (event: MessageEvent) => {
            try {
                const data = JSON.parse(event.data) as T;
                setData(data);
            } catch (error) {
                console.error("Invalid message format");
            }
        };

        webSocket.onclose = (event: Event) => {
            console.log("WebSocket connection is closed.");
        };

        return () => {
            webSocket.close();
        };
    }, [url, shouldConnect]);

    return data;
}

export default useWebSocketApi;
