import React, {useState} from 'react';
import {Button, Table, theme} from "antd";
import {ColumnsType} from "antd/es/table";
import {QueryKey, useQueryClient} from "@tanstack/react-query";
import {useCommonUseQuery} from "../../../common/hooks/rest/ReacQueryHook";
import Search from "antd/es/input/Search";
import {formatDate} from "../../../common/util/DateUtil";
import UserAvatar from "../../index/header/UserAvatar/UserAvatar";
import {procGetUserList, UserData} from "../../../rest/admin/UserMgr";
import {procGetAesMgrList, TB_SECURITY_AES} from "../../../rest/admin/SecurityAesMgr";
import {procGetJwtMgrList, TB_SECURITY_JWT} from "../../../rest/admin/SecurityJwtMgr";
import {
    procGetStorageWizStorageList,
    procGetStorageWizUrlList,
    TB_STORAGE_MGR,
    TB_STORAGEWIZ
} from "../../../rest/admin/StorageWizMgr";

const StorageMgr: React.FC = () => {
    const { token: { colorBgContainer },} = theme.useToken();
       const queryClient = useQueryClient();
    const queryKey:QueryKey =   [`storage/rest`];
    const { data} = useCommonUseQuery<TB_STORAGE_MGR[]>(
        queryKey,
        () =>procGetStorageWizStorageList());


    const columns: ColumnsType<TB_STORAGE_MGR> = [
        {
            title: '스토리지명',
            dataIndex: 'storageName',
            key: 'storageName',
            render: (text: string) =>
                <div style={{ paddingBottom: '9px', margin:'0', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    {text}
                </div>
            ,
        },
        {
            title: '스토리지 경로',
            dataIndex: 'mainPath',
            key: 'mainPath',
            render: (text: string) =>
                <div style={{ paddingBottom: '9px', margin:'0', display: 'flex', alignItems: 'left', justifyContent: 'left' }}>
                    {text}
                </div>
            ,
        },
        {
            title: '폴더당 저장개수',
            dataIndex: 'limitCnt',
            key: 'limitCnt',
            render: (text: string) =>
                <div style={{ paddingBottom: '9px', margin:'0', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    {text}
                </div>
            ,
        },
        {
            title: '생성일자',
            key: 'lastRegTime',
            dataIndex: 'lastRegTime',
            render: (text: string) =>
                <div style={{ paddingBottom: '9px', margin:'0', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    {formatDate(text)}
                </div>
            ,
        },

    ];

    return (
        <>
        <Table columns={columns}
               dataSource={data}
               rowKey="jwtKey"
        />
        </>
    );
};

export default StorageMgr;
