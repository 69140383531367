type UnitMap = {
    [unit: string]: number;
};

export function fileSizeToBytes(fileSizeStr: string): number {
    const sizeRegex = /^(\d+(\.\d+)?)(B|KB|MB|GB|TB|PB|EB|ZB)$/i;
    const match = sizeRegex.exec(fileSizeStr);

    if (!match) {
        throw new Error(`Invalid file size format: ${fileSizeStr}. Supported format: {size}{unit}, e.g., 100MB`);
    }

    const size = parseFloat(match[1]);
    const unit = match[3].toUpperCase();

    const units:UnitMap = {
        B: 1,
        KB: 1024,
        MB: 1024 ** 2,
        GB: 1024 ** 3,
        TB: 1024 ** 4,
        PB: 1024 ** 5,
        EB: 1024 ** 6,
        ZB: 1024 ** 7,
    };

    return size * units[unit];
}

export function bytesToFileSizeString(bytes: number): string {
    const units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB'];
    let unitIndex = 0;

    while (bytes >= 1024 && unitIndex < units.length - 1) {
        bytes /= 1024;
        unitIndex++;
    }

    // Round to one decimal place for MB and to two decimal places for GB and above
    if (unitIndex === 2) {
        // MB
        bytes = Math.round(bytes * 10) / 10;
    } else if (unitIndex > 2) {
        // GB and above
        bytes = Math.round(bytes * 100) / 100;
    } else {
        // B and KB
        bytes = Math.round(bytes);
    }

    return `${bytes}${units[unitIndex]}`;
}

console.log(bytesToFileSizeString(3158301)); // 출력 예: "3.0MB"
