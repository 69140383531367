import * as React from 'react';
import {DocumentLoadEvent, SpecialZoomLevel, Viewer,  Worker} from '@react-pdf-viewer/core';
import {zoomPlugin, ZoomPluginProps} from '@react-pdf-viewer/zoom';
import { printPlugin } from '@react-pdf-viewer/print';
import { fullScreenPlugin } from '@react-pdf-viewer/full-screen';
import { rotatePlugin } from '@react-pdf-viewer/rotate';
import { getFilePlugin } from '@react-pdf-viewer/get-file';
import { pageNavigationPlugin } from '@react-pdf-viewer/page-navigation';

import './PDFViewer.css'

import '@react-pdf-viewer/page-navigation/lib/styles/index.css';

import '@react-pdf-viewer/full-screen/lib/styles/index.css';

import '@react-pdf-viewer/print/lib/styles/index.css';

import '@react-pdf-viewer/zoom/lib/styles/index.css';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/thumbnail/lib/styles/index.css';
import {useEffect, useState} from "react";
import {Button, Dropdown, MenuProps, Space, theme, Tooltip} from "antd";
import {
    CaretLeftOutlined,
    CaretRightOutlined,
    DownloadOutlined,
    FullscreenOutlined,
    PercentageOutlined,
    PrinterOutlined,
    ZoomInOutlined,
    ZoomOutOutlined
} from "@ant-design/icons";
import useDownloadFile from "../../hooks/rest/useDownloadFile";
interface PdfViewerProps {
    fileUrl: string,
    docId:string,
    version:number,
    fileName:string,
}

export const PDFViewer = ( props:PdfViewerProps ) => {

    const { token: { colorBgContainer },} = theme.useToken();
    const [selectedPages, setSelectedPages] = React.useState<boolean[]>([]);
    const [currentPage, setCurrentPage] = useState<number>(1);

    const downloadFile = useDownloadFile();

    const handleDocumentLoad = (e: DocumentLoadEvent) => {
        setSelectedPages(Array(e.doc.numPages).fill(false));
        console.log(selectedPages);
    };


    const zoozProps:ZoomPluginProps = {
        enableShortcuts:true,
    }

    const zoomPluginInstance = zoomPlugin(zoozProps);
    const { ZoomIn, ZoomOut } = zoomPluginInstance;


    const printPluginInstance = printPlugin();
    const { Print } = printPluginInstance;

    const fullScreenPluginInstance = fullScreenPlugin();
    const {EnterFullScreen} = fullScreenPluginInstance;

    const getFilePluginInstance = getFilePlugin();

    const rotatePluginInstance = rotatePlugin();

    const pageNavigationPluginInstance = pageNavigationPlugin();
    const {CurrentPageInput,NumberOfPages, GoToPreviousPage, GoToNextPage } = pageNavigationPluginInstance;




    const items:MenuProps['items'] = [
        {
            key: 'ACTUAL_SIZE',
            label: '실제크기',
        },
        {
            key: 'WIDTH_FIT',
            label: '가로에 맞추기',
        },
        {
            key: 'HEIGHT_FIT',
            label: '세로에 맞추기',
        },
        {
            key: '0.5',
            label: '50%',
        },
        {
            key: '0.75',
            label: '70%',
        },
        {
            key: '1.0',
            label: '100%',
        },
        {
            key: '1.25',
            label: '125%',
        },
        {
            key: '1.5',
            label: '150%',
        },
        {
            key: '2.0',
            label: '200%',
        },
        {
            key: '3.0',
            label: '300%',
        },
        {
            key: '4.0',
            label: '400%',
        },



    ];
    const handleRatioMenuClick: MenuProps['onClick'] = (e) => {

        const key:string = e.key;
        switch (key)
        {
            case 'ACTUAL_SIZE' :
                zoomPluginInstance.zoomTo(SpecialZoomLevel.ActualSize);
                break;
            case 'WIDTH_FIT' :
                zoomPluginInstance.zoomTo(SpecialZoomLevel.PageWidth);
                break;
            case 'HEIGHT_FIT' :
                zoomPluginInstance.zoomTo(SpecialZoomLevel.PageFit);
                break;
            case '0.5' :
                zoomPluginInstance.zoomTo(0.5);
                break;
            case '0.75' :
                zoomPluginInstance.zoomTo(0.75);
                break;
            case '1.0' :
                zoomPluginInstance.zoomTo(1.0);
                break;
            case '1.25' :
                zoomPluginInstance.zoomTo(1.25);
                break;
            case '1.5' :
                zoomPluginInstance.zoomTo(1.5);
                break;
            case '2.0' :
                zoomPluginInstance.zoomTo(2.0);
                break;
            case '3.0' :
                zoomPluginInstance.zoomTo(3.0);
                break;
            case '4.0' :
                zoomPluginInstance.zoomTo(4.0);
                break;
            default:
                zoomPluginInstance.zoomTo(SpecialZoomLevel.ActualSize);
                break;
        }
    };

    const raioMenuProps = {
        items,
        onClick: handleRatioMenuClick,
    };


    useEffect(() => {
        zoomPluginInstance.zoomTo(SpecialZoomLevel.PageFit);
    }, [zoomPluginInstance]);


    return (
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.5.141/build/pdf.worker.min.js">
        <div
            style={{
                 width: '100%',
            }}
        >
            <nav style={{
                margin:'0px 0px 4px 0px',
                padding:'9px 8px 9px 0px',
                height: '50px',
                display:'flex',
                justifyContent: "space-between",
                alignItems:'center',
                borderRadius: "8px", // 라운드 처리 추가
                border: '1px solid #dadada',

                background: colorBgContainer,
            }}>
                <div style={{ width:'34%', marginLeft: '8px' }}>

                    <Space.Compact  style={{
                        width:'100%',
                        justifyContent:'left',
                        alignItems:'center',
                        display:'flex'
                    }} block>
                       <GoToPreviousPage>
                            {({ onClick }) =>   <Tooltip title="이전페이지" arrow={false}>
                                                    <Button type="primary" icon={<CaretLeftOutlined />} onClick={() => { onClick(); setCurrentPage(currentPage+1); }}/>
                                                </Tooltip>
                            }
                        </GoToPreviousPage>

                        <CurrentPageInput/> / <span style={{marginRight:'10px'}} ><NumberOfPages/></span>

                        <GoToNextPage>
                            {({ onClick }) => <Tooltip title="다음페이지" arrow={false}>
                                                    <Button type="primary" icon={<CaretRightOutlined />} onClick={() => { onClick(); setCurrentPage(currentPage+1); }}/>
                                              </Tooltip>
                            }
                        </GoToNextPage>
                    </Space.Compact>


                </div>
                <div style={{ width:'33%', textAlign: 'center', flexGrow: 1 }}>
                    <Space.Compact  style={{
                        width:'100%',
                        justifyContent:'center',
                    }} block>
                    <ZoomIn >
                        {({ onClick }) =><Tooltip title="확대" arrow={false}>
                                            <Button type="primary" icon={<ZoomInOutlined />} onClick={onClick}/>
                                         </Tooltip>}
                    </ZoomIn>

                    <Tooltip title="비율로보기">
                        <Dropdown
                            placement="bottomCenter"
                            menu={raioMenuProps}
                        >
                            <Button  type="primary" icon={<PercentageOutlined />}/>
                        </Dropdown  >
                    </Tooltip>
                    <ZoomOut  >
                        {({ onClick }) =><Tooltip title="축소" arrow={false}>
                                            <Button type="primary" icon={<ZoomOutOutlined />} onClick={onClick}/>
                                        </Tooltip>}
                    </ZoomOut >
                    </Space.Compact>
                </div>
                <div style={{ width:'33%',  marginRight: '8px',textAlign: 'right' }}>
                    <Space.Compact  style={{
                        width:'100%',
                        justifyContent:'flex-end',
                    }} block>
                   <EnterFullScreen>
                        {({ onClick }) =><Tooltip title="전체화면" arrow={false}>
                                            <Button type="primary" icon={<FullscreenOutlined />} onClick={onClick}/>
                                        </Tooltip>}
                   </EnterFullScreen>
                    <Tooltip title="다운로드" arrow={false}>
                        <Button type="primary" icon={<DownloadOutlined />} onClick={() => {
                            const url:string = "rest/docuwiz/document/download";
                            const docId:string = props.docId;
                            const docVersion:number = props.version;
                            const defFileName:string = props.fileName;
                            downloadFile({
                                url: url,
                                docId: docId,
                                defFileName: defFileName,
                                docVersion: docVersion
                            }).then();
                            }
                        }/>
                    </Tooltip>
                    <Print>
                        {({ onClick }) => <Tooltip title="인쇄" arrow={false}>
                                            <Button type="primary" icon={<PrinterOutlined />} onClick={onClick}/>
                                        </Tooltip>}
                    </Print>
                    </Space.Compact>
                </div>
            </nav>
            <div
                style={{
                    border: '1px solid rgba(0, 0, 0, 0.1)',
                    display: 'flex',
                    height: 'calc( 100vh - 120px )',
                }}

            >

                <div
                    style={{
                        width:'100%',
                    }}
                >
                    <Viewer defaultScale={1.0} onDocumentLoad={handleDocumentLoad} fileUrl={props.fileUrl} plugins={[ //thumbnailPluginInstance,
                                                                                             zoomPluginInstance
                                                                                            , printPluginInstance
                                                                                            , fullScreenPluginInstance
                                                                                            , rotatePluginInstance
                                                                                            , getFilePluginInstance
                                                                                            , pageNavigationPluginInstance]} />
                </div>
            </div>
        </div>
        </Worker>
    );
};