
import React, {useContext, useState} from 'react';
import { SendOutlined} from '@ant-design/icons';
import TextArea from "antd/es/input/TextArea";
import {Button, message} from "antd";
import {procInsertCommentData} from "../../../../../rest/comment/Comment";
import {CommentData} from "../data/CommentData";
import {QueryKey, useQueryClient} from "@tanstack/react-query";
import {useCommonUseMutation} from "../../../../../common/hooks/rest/ReacQueryHook";
import ViewerOptionContext from "../../../../../context/ViewerOptionContext";
import ViewerDetailContext from "../../../../../context/ViewerDetailContext";

interface commentInputProps extends CommentData{
    showCommentInput?:boolean|null,
    mode? : 'reply' | 'noreply' | 'modify',
    commentTxt?:string,
    setShowReplyCommentId: (value: string) => void;
    setModifyInput?: (value: boolean) => void;

}
const CommentInput = (props : commentInputProps) => {

    const [commentText, setCommentText] = useState<string>(props.commentTxt?props.commentTxt : '');
    const { viewerOption } = useContext(ViewerOptionContext);
    const { setRefetchViewer } = useContext(ViewerDetailContext);

    const queryClient = useQueryClient();
    const queryKey:QueryKey =   [`comment_${props.docId}`];

    const mutateUpdate = useCommonUseMutation<CommentData>(queryKey, queryClient, procInsertCommentData);

    const handleTextAreaChange = (e:any) => {
        setCommentText(e.target.value);
    };
    function getCommentMode():string {
        let commentMode:string;
        if (props.mode === null)
        {
            commentMode = 'noreply';
        }
        else
        {
            commentMode = props.mode as string;
        }

        return commentMode;
    }

    function addComment()
    {
        if(commentText.length === 0)
        {
            message.warning('코멘트 내용을 입력해주세요.');
        }
        else {
            const commentData: CommentData = {
                commentId: props.commentId === null ? null : props.commentId as string,
                parentCommentId: props.parentCommentId === null ? null : props.parentCommentId as string,
                comment: commentText,
                docId: props.docId,
                userId: viewerOption?.jwtSession?.userId,
                userName: viewerOption?.jwtSession?.userName,
                targetUserId: props.targetUserId === null ? null : props.targetUserId as string,
                targetUserName: props.targetUserName === null ? null : props.targetUserName as string,
            }
            mutateUpdate.mutateAsync(commentData).then();
            setRefetchViewer(true);
            props.setShowReplyCommentId('');

            if (props.setModifyInput) {
                props.setModifyInput(false);
            }

            setCommentText('');

        }

    }

    return (
        <div style={{
            display : "flex",
            justifyContent:'center',
            alignItems:"center",
            height:'45px',
            marginBottom:'25px'
        }}>
            <div style={{
                width:'calc(100% - 50px)',marginTop:'10px',
            }}>
                <TextArea
                    onChange={handleTextAreaChange}
                    showCount
                    maxLength={200}
                    style={getCommentMode() === 'modify' ? {height: 55, resize: 'none' } : {height: 34, resize: 'none' }}
                    placeholder={getCommentMode() === 'reply' ?  "답글을 남겨보세요." : "코멘트를 남겨보세요." }
                    value={commentText}
                />
            </div>
            <div style={{
                width:'50px',
                paddingTop:'22px',
                paddingLeft:'8px',
                paddingRight:'10px',
                paddingBottom:'10px',
            }}>
                <Button onClick={addComment} type="primary" icon={<SendOutlined />} />
            </div>
        </div>
    );
};

export default CommentInput;