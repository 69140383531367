import React, {useState, useEffect, useContext} from 'react';
import DocuwizToolbar from "../Toolbar/DocuwizToolbar/DocuwizToolbar";
import DocuwizViewerContext, {DocuWizViewerContextType} from "../../../../context/DocuwizViewerContext";
import DocuwizViewer from "../Viewer/DocuwizViewer/DocuwizViewer";

const ContenMain = () => {
    const savedViewMode = localStorage.getItem('viewMode');

    const initialDocuWizOpt: DocuWizViewerContextType = {
        checkedItem: [],
        setCheckedItem: () => {},
        listMode: savedViewMode ? savedViewMode : "Thumb",
        setListMode: () => {},
        allSelected: false,
        setAllSelected: () => {},
        showDeleteBox: false,
        setShowDeleteBox: () => {},
        indeterminate: false,
        setIndeterminate: () => {},
        sortBy: "SORT_BY_ORDER_ASC",
        setSortBy: () => {},
    };

    const [docuWizOpt, setDocuWizOpt] = useState<DocuWizViewerContextType>(initialDocuWizOpt);



    useEffect(() => {
        localStorage.setItem('viewMode', docuWizOpt?.listMode as string);
    }, [docuWizOpt?.listMode]);

        return (

            <DocuwizViewerContext.Provider
                value={{
                    checkedItem: docuWizOpt.checkedItem,
                    setCheckedItem: (value) => setDocuWizOpt((prevDocuWizOpt) => ({ ...prevDocuWizOpt, checkedItem: value })),
                    listMode: docuWizOpt.listMode,
                    setListMode: (value) => setDocuWizOpt((prevDocuWizOpt) => ({ ...prevDocuWizOpt, listMode: value })),
                    allSelected: docuWizOpt.allSelected,
                    setAllSelected: (value) => setDocuWizOpt((prevDocuWizOpt) => ({ ...prevDocuWizOpt, allSelected: value })),
                    showDeleteBox: docuWizOpt.showDeleteBox,
                    setShowDeleteBox: (value) => setDocuWizOpt((prevDocuWizOpt) => ({ ...prevDocuWizOpt, showDeleteBox: value })),
                    indeterminate: docuWizOpt.indeterminate,
                    setIndeterminate: (value) => setDocuWizOpt((prevDocuWizOpt) => ({ ...prevDocuWizOpt, indeterminate: value })),
                    sortBy: docuWizOpt.sortBy,
                    setSortBy: (value) => setDocuWizOpt((prevDocuWizOpt) => ({ ...prevDocuWizOpt, sortBy: value })),
                }}
            >
                <DocuwizToolbar/>
                <DocuwizViewer />
            </DocuwizViewerContext.Provider>
        );
};

export default ContenMain;