import axiosInstance from "../../common/util/AxiosInstance";
import {ViewerOption} from "../../data/ViewerOption";

export interface RequestOption {
    encryptInterfaceId: string,
    encryptInterfaceMode: string,
    requestPageSize:number,
}

export async function  procGetOption(requestParam:RequestOption):Promise<ViewerOption>{

    const response = await axiosInstance.request<ViewerOption>(
        {
            method: "POST",
            url: `rest/docuwiz/document/option`,
            headers: {
                'Content-Type': 'application/json',
            },
            data:requestParam,
            params:   requestParam
        });

    return response.data;
};