import * as React from 'react';
import {useEffect, useRef, useState} from "react";
import {Button, Dropdown, MenuProps, Space, theme, Tooltip} from "antd";
import {
    DownloadOutlined,
    FullscreenOutlined,
    PercentageOutlined,
    PrinterOutlined, RedoOutlined,
    ZoomInOutlined,
    ZoomOutOutlined
} from "@ant-design/icons";

import './ImageViewer.css'
import createPanZoom from "panzoom";

interface CustomThumbnailItemsExampleProps {
    fileUrl: string;
    fileName:string;
}

export const ImageViewer: React.FC<CustomThumbnailItemsExampleProps> = ({ fileUrl, fileName }) => {

    const { token: { colorBgContainer },} = theme.useToken();

    const [curRatio, setCurRatio] = useState<number>(1.0);

    const panZoomRef = useRef<HTMLImageElement>(null);
    const imageRef = useRef<HTMLImageElement>(null);
    const [panZoomInstance, setPanZoomInstance] = useState<any>(null);

// 이전 useEffect를 다음과 같이 수정하세요.
    useEffect(() => {
        if (imageRef.current) {
            const instance = createPanZoom(imageRef.current, {
                maxZoom: 4.0,
                minZoom: 0.3,
                bounds: true,
                boundsPadding: 0,

            });

            setPanZoomInstance(instance); // useState를 사용하여 panZoomInstance를 설정합니다.

            return () => {
                instance?.dispose(); // 컴포넌트가 언마운트될 때 panzoom 인스턴스를 제거합니다.
            };
        }
    }, []);



    const items:MenuProps['items'] = [
        {
            key: 'ACTUAL_SIZE',
            label: '실제크기',
        },
        {
            key: 'WIDTH_FIT',
            label: '가로에 맞추기',
        },
        {
            key: 'HEIGHT_FIT',
            label: '세로에 맞추기',
        },
        {
            key: '0.5',
            label: '50%',
        },
        {
            key: '0.75',
            label: '70%',
        },
        {
            key: '1.0',
            label: '100%',
        },
        {
            key: '1.25',
            label: '125%',
        },
        {
            key: '1.5',
            label: '150%',
        },
        {
            key: '2.0',
            label: '200%',
        },
        {
            key: '3.0',
            label: '300%',
        },
        {
            key: '4.0',
            label: '400%',
        },

    ];

    const Enlargement = () => {
        if (panZoomRef.current && imageRef.current) {
            setScale(curRatio + 0.15);
        }
    };

    const Reduction = () => {
        if (panZoomRef.current && imageRef.current) {
            setScale(curRatio - 0.15);
        }
    };
    const fitWidth = () => {
        if (panZoomRef.current && imageRef.current) {
            const containerWidth = panZoomRef.current.clientWidth;
            const scaleFactor = containerWidth / imageRef.current.clientWidth;
            if (panZoomInstance) {
                panZoomInstance.zoomAbs(0, 0, scaleFactor);
            }
        }
    };

    const fitHeight = () => {
        if (panZoomRef.current && imageRef.current) {
            const containerHeight = panZoomRef.current.clientHeight;
            const scaleFactor = containerHeight / imageRef.current.clientHeight;
            if (panZoomInstance) {
                panZoomInstance.zoomAbs(0, 0, scaleFactor);
            }
        }
    };

    const resetPanZoom = () => {
        if (panZoomInstance) {
            panZoomInstance.dispose(); // 현재 인스턴스를 해제합니다.

            if(imageRef.current)
            {
                // 새 panzoom 인스턴스를 생성하고 설정합니다.
                const newInstance = createPanZoom(imageRef.current, {
                    maxZoom: 4.0,
                    minZoom: 0.3,
                    bounds: true,
                    boundsPadding: 0,
                });

                setPanZoomInstance(newInstance); // 새 인스턴스를 설정합니다.
                setCurRatio(1.0); // 현재 비율을 1.0으로 설정합니다.
            }

        }
    };

    const setScale = (scale: number) => {
        if (panZoomInstance && imageRef.current && panZoomRef.current) {
            const containerWidth = panZoomRef.current.clientWidth;
            const containerHeight = panZoomRef.current.clientHeight;
            const imageWidth = imageRef.current.clientWidth;
            const imageHeight = imageRef.current.clientHeight;

            const centerX = (containerWidth - imageWidth) / 2;
            const centerY = (containerHeight - imageHeight) / 2;

            panZoomInstance.moveTo(centerX, centerY);
            panZoomInstance.zoomAbs(centerX, centerY, scale);
            setCurRatio(scale);
        }
    };
    const handleRatioMenuClick: MenuProps['onClick'] = (e) => {

        const key:string = e.key;
        switch (key)
        {
            case 'ACTUAL_SIZE' :
                setScale(1.0);
                break;
            case 'WIDTH_FIT' :
                fitWidth();
                break;
            case 'HEIGHT_FIT' :
                fitHeight();
                break;
            case '0.5' :
                setScale(0.5);
                break;
            case '0.75' :
                setScale(0.75);
                break;
            case '1.0' :
                setScale(1.0);
                break;
            case '1.25' :
                setScale(1.25);
                break;
            case '1.5' :
                setScale(1.5);
                break;
            case '2.0' :
                setScale(2.0);
                break;
            case '3.0' :
                setScale(3.0);
                break;
            case '4.0' :
                setScale(4.0);
                break;
            default:
                fitHeight();
                break;
        }
    };

    const toggleFullscreen = () => {
        if (imageRef.current) {
            const image = imageRef.current as any;
            if (document.fullscreenElement) {
                document.exitFullscreen();
            } else {
                if (image.requestFullscreen) {
                    image.requestFullscreen();
                } else if (image.msRequestFullscreen) {
                    image.msRequestFullscreen();
                } else if (image.mozRequestFullScreen) {
                    image.mozRequestFullScreen();
                } else if (image.webkitRequestFullscreen) {
                    image.webkitRequestFullscreen();
                }
            }
        }
    };
    const printImage = () => {
        if (imageRef.current) {
            const printIframe = document.createElement('iframe');
            printIframe.width = '0';
            printIframe.height = '0';
            printIframe.style.display = 'none';
            document.body.appendChild(printIframe);

            const printWindow = printIframe.contentWindow;
            if (printWindow) {
                printWindow.document.write('<html><head><title>'+fileName+'</title>');
                printWindow.document.write('<style>@media print { @page { size: auto; margin: 0; } } body { display: flex; justify-content: center; align-items: center; height: 100%; margin: 0; padding: 0; } img { max-height: 100%; max-width: 100%; object-fit: contain; margin: auto; }</style>');
                printWindow.document.write('</head><body>');
                printWindow.document.write('<img src="' + imageRef.current.src + '" />');
                printWindow.document.write('</body></html>');
                printWindow.document.close();

                setTimeout(() => {
                    printWindow.print();
                    document.body.removeChild(printIframe);
                }, 500);
            }
        }
    };

    const downloadImage = () => {
        if (imageRef.current) {
            console.log(imageRef.current.src);
            const aElement = document.createElement('a');
            aElement.href = imageRef.current.src;
            document.body.appendChild(aElement);
            aElement.download = fileName;
            // 부착 시킨 a태그를 Click!(이벤트 발생 시키기) 그러면 다운로드가 된다.
            aElement.click();
            setTimeout(() => {
                // 이제 더이상 필요 없으니 생성한 a태그를 1초후 삭제 시켜준다.
                aElement.remove();
            }, 1000);

        }
    }


    const raioMenuProps = {
        items,
        onClick: handleRatioMenuClick,
    };


    return (
        <div
            style={{
                 width: '100%',
            }}
        >
            <nav style={{
                margin:'0px 0px 4px 0px',
                padding:'9px 8px 9px 0px',
                height: '50px',
                display:'flex',
                justifyContent: "space-between",
                alignItems:'center',
                borderRadius: "8px", // 라운드 처리 추가
                border: '1px solid #dadada',

                background: colorBgContainer,
            }}>
                <div style={{ width:'34%', marginLeft: '8px' }}>

                    <Space.Compact  style={{
                        width:'100%',
                        justifyContent:'left',
                        alignItems:'center',
                        display:'flex'
                    }} block>
                        <Tooltip title="처음위치로" arrow={false}>
                            <Button type="primary" icon={<RedoOutlined />} onClick={resetPanZoom}/>
                        </Tooltip>
                    </Space.Compact>


                </div>
                <div style={{ width:'33%', textAlign: 'center', flexGrow: 1 }}>
                    <Space.Compact  style={{
                        width:'100%',
                        justifyContent:'center',
                    }} block>

                        <Tooltip title="확대" arrow={false}>
                            <Button type="primary" icon={<ZoomInOutlined />} onClick={Enlargement}/>
                        </Tooltip>
                    <Tooltip title="비율로보기" arrow={false}>
                        <Dropdown
                            placement="bottomCenter"
                            menu={raioMenuProps}
                        >
                            <Button  type="primary" icon={<PercentageOutlined />}/>
                        </Dropdown  >
                    </Tooltip>
                    <Tooltip title="축소" arrow={false}>
                        <Button type="primary" icon={<ZoomOutOutlined />} onClick={Reduction}/>
                    </Tooltip>
                    </Space.Compact>
                </div>
                <div style={{ width:'33%',  marginRight: '8px',textAlign: 'right' }}>
                    <Space.Compact  style={{
                        width:'100%',
                        justifyContent:'flex-end',
                    }} block>
                        <Tooltip title="전체화면" arrow={false}>
                            <Button type="primary" icon={<FullscreenOutlined />} onClick={toggleFullscreen} />
                        </Tooltip>
                        <Tooltip title="다운로드" arrow={false}>
                            <Button type="primary" icon={<DownloadOutlined />} onClick={downloadImage}/>
                        </Tooltip>
                        <Tooltip title="인쇄" arrow={false}>
                            <Button type="primary" icon={<PrinterOutlined />} onClick={printImage}/>
                        </Tooltip>
                    </Space.Compact>
                </div>
            </nav>
            <div
                ref={panZoomRef}
                style={{
                    border: '1px solid rgba(0, 0, 0, 0.1)',
                    display: 'flex',
                    height: 'calc( 100vh - 120px )',
                    justifyContent: 'center', // 이미지를 수평으로 중앙 정렬합니다.
                    alignItems: 'center',
                    width:'100%',
                    position: 'relative', // 추가: 부모 div에 상대적으로 이미지를 위치시킵니다.
                    overflow: 'hidden', // 추가: 이미지가 div 바깥으로 나가는 것을 방지합니다.
                }}
            >

                        <img
                            ref={imageRef}
                            src={fileUrl}
                            alt={fileName}
                            style={{
                                height :'100%',
                                userSelect: 'none',
                            }}
                        />


            </div>
        </div>
    );
};