import React, { useState, useEffect, useRef } from 'react';

interface Size {
    width: number;
    height: number;
}

function useContainerSize(ref: React.RefObject<HTMLElement>): Size {
    const [size, setSize] = useState<Size>({ width: 0, height: 0 });
    const observer = useRef<ResizeObserver | null>(null);

    useEffect(() => {
        const updateSize = () => {
            if (ref.current) {
                setSize({
                    width: ref.current.clientWidth,
                    height: ref.current.clientHeight,
                });
            }
        };

        observer.current = new ResizeObserver(updateSize);
        if(ref.current){
            observer.current.observe(ref.current);
        }

        updateSize();

        return () => {
            if(observer.current){
                observer.current.disconnect();
            }
        };
    }, [ref]);

    return size;
}

export default useContainerSize;
