import React, {useContext, useState} from 'react';
import {Button, Modal, Tooltip} from 'antd';
import FileAvatar from "../FileListViewer/FileAvatar";
import {CheckOutlined, DeleteOutlined, DownloadOutlined} from "@ant-design/icons";
import {
    documentParam,
    procActiveDocVer,
    procDeleteFileVersion,
    procGetFileAllVersion
} from "../../../../../rest/docuwizViewer/VersionRest";
import {QueryKey, useQueryClient} from "@tanstack/react-query";
import {useCommonUseMutation, useCommonUseQuery} from "../../../../../common/hooks/rest/ReacQueryHook";
import {DocumentList} from "../data/DocumentList";
import {formatDate} from "../../../../../common/util/DateUtil";
import ViewerDetailContext from "../../../../../context/ViewerDetailContext";
import useDownloadFile from "../../../../../common/hooks/rest/useDownloadFile";
import ViewerOptionContext from "../../../../../context/ViewerOptionContext";



const VersionList= () => {

    const { viewerOption } = useContext(ViewerOptionContext);
    const { docId } = useContext(ViewerDetailContext);
    const { setRefetchViewer } = useContext(ViewerDetailContext);
    const queryKey:QueryKey =   [`verinfo_${docId}`];
    const queryClient = useQueryClient();
    const downloadFile = useDownloadFile();
    const [showDeleteBox, setShowDeleteBox] = useState<boolean>(false);
    const { data } = useCommonUseQuery<DocumentList[]>(
        queryKey,
        () =>procGetFileAllVersion(docId as string));

    const mutateUpdate = useCommonUseMutation<DocumentList>(queryKey, queryClient, procActiveDocVer);
    const mutateDelete = useCommonUseMutation<boolean>(queryKey, queryClient, procDeleteFileVersion);


    return (
            <div style={{marginTop:"20px", height:'300px', overflowY:"auto"}}>
                {data &&
                    data.map((item, index) => (

                        <div key={index} style={{display:"flex", marginTop:"10px", paddingBottom:"5px",borderBottom:"1px solid lightgrey"}}>
                            <div style={{width:"50px"}}>
                                <FileAvatar extension={item.extension}/>
                            </div>
                            <div style={{width:"calc(100% - 50px)", flexDirection:"column"}}>
                                <div style={{flex:1, display:"flex"}}>
                                    <div style={{width:"50px",
                                        backgroundColor: item.docStatus === true ? `red` : `grey`,
                                        fontSize:"11px",
                                        color:"white",
                                        fontWeight:"bold",
                                        borderRadius:"10px",
                                        display:"flex",
                                        alignItems:"center",
                                        justifyContent:"center",
                                        marginRight:"8px",
                                    }}>
                                        {
                                            item.docStatus === true ? `활성버전` : `버전${item.fileVersion}.0`
                                        }
                                    </div>
                                    <div style={{width:"calc(100% - 50px)",
                                        fontWeight:"bold",
                                    }}>
                                        <div style={{
                                            width:"100%",
                                            textOverflow:"ellipsis",
                                            whiteSpace:"nowrap",
                                            maxWidth:"100%",
                                            overflow:"hidden",

                                        }}>
                                            {item.fileName}
                                        </div>

                                    </div>
                                </div>
                                <div style={{flex:1, display:"flex"}}>
                                    <div style={{width:"130px",
                                        fontSize:"11px",
                                        paddingLeft:"7px",
                                        display:"flex",
                                        alignItems:"left",
                                        justifyContent:"left",
                                        position:"relative",
                                    }}>
                                        {formatDate(item.formatCreateTime)}
                                    </div>
                                    <div style={{width:"calc(100% - 130px)",
                                        display:"flex",
                                        alignItems:"right",
                                        justifyContent:"right",
                                    }}>
                                        {viewerOption?.mode === 'rw' &&
                                            <Tooltip title="이 버전을 활성화" arrow={false}>
                                                <Button className="fileListRightBtn" shape="circle" size="small" icon={<CheckOutlined />} onClick={(e) => {
                                                    e.stopPropagation();
                                                    const param :documentParam = {
                                                        docId:item.docId,
                                                        docVer:item.fileVersion
                                                    }
                                                    mutateUpdate.mutateAsync(param);
                                                    setRefetchViewer(true);
                                                }}/>
                                            </Tooltip>
                                        }
                                        <Tooltip title="이 버전을 다운로드" arrow={false}>
                                            <Button className="fileListRightBtn" shape="circle" size="small" icon={<DownloadOutlined />} onClick={(e) => {
                                                e.stopPropagation();
                                                const url:string = "rest/docuwiz/document/download";
                                                const docId:string = item.docId;
                                                const docVersion:number = item.fileVersion;
                                                const defFileName:string = item.fileName;
                                                downloadFile({
                                                    url: url,
                                                    docId: docId,
                                                    defFileName: defFileName,
                                                    docVersion: docVersion
                                                }).then();
                                            }}/>
                                        </Tooltip>
                                        {
                                            (!item.docStatus && viewerOption?.mode === 'rw') &&
                                                <Tooltip title="이 버전을 삭제" arrow={false}>
                                                    <Button className="fileListRightBtn" shape="circle" size="small" icon={<DeleteOutlined />} onClick={(e) => {
                                                        e.stopPropagation();
                                                        Modal.confirm({
                                                            title: `${item.fileName} (Ver.${item.fileVersion}.0)을(를) 삭제하시겠습니까?`,
                                                            content: "삭제 후에는 복구되지 않습니다.",
                                                            open: showDeleteBox,
                                                            okText: "확인",
                                                            cancelText: "취소",
                                                            async onOk() {
                                                                setShowDeleteBox(false);
                                                                const param :documentParam = {
                                                                    docId:item.docId,
                                                                    docVer:item.fileVersion
                                                                }
                                                                mutateDelete.mutateAsync(param);
                                                            },
                                                            onCancel() {
                                                                setShowDeleteBox(false);
                                                            },
                                                        });

                                                    }}/>
                                                </Tooltip>

                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                }

            </div>
    );
};

export default VersionList;