import {Checkbox, theme, Tooltip} from "antd";
import SlectListType from "../SelectListType/SlectListType";
import React, {useContext, useEffect} from "react";
import RightButtonGroup from "../RightButtonGroup/RightButtonGroup";
import DocuwizViewerContext from "../../../../../context/DocuwizViewerContext";
import ViewerOptionContext from "../../../../../context/ViewerOptionContext";


const DocuwizToolbar = ( ) => {
    const { allSelected ,setAllSelected
            ,indeterminate, setIndeterminate,
            listMode, checkedItem, setSortBy, setShowDeleteBox} = useContext(DocuwizViewerContext);

    const { viewerOption } = useContext(ViewerOptionContext);

    const { token: { colorBgContainer },} = theme.useToken();
    const handleAllSelectedToggleAll = () => {
        setAllSelected(!allSelected);
    };

    useEffect(() => {
        setAllSelected(allSelected);
        setIndeterminate(indeterminate)

    }, [allSelected, indeterminate]);


    return (
        <>
            <nav style={{
                margin:'0px 0px 4px 0px',
                padding:'9px 8px 9px 0px',
                height: '50px',
                display:'flex',
                justifyContent: "space-between",
                alignItems:'center',
                borderRadius: "8px", // 라운드 처리 추가
                border: '1px solid #dadada',
                background: colorBgContainer,
            }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <div style={{
                        padding: '9px 5px',
                        display:'flex',
                    }}>
                        {
                            listMode === "Thumb" && viewerOption?.mode === "rw" && (
                                <Tooltip title="전체선택">
                                    <Checkbox  indeterminate={indeterminate as boolean} onChange={handleAllSelectedToggleAll} checked={allSelected as boolean}/>
                                </Tooltip>
                            )
                        }

                    </div>

                    <SlectListType/>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <RightButtonGroup checkedItems={checkedItem as React.Key[]} setSortBy={setSortBy} setShowDeleteBox={setShowDeleteBox}/>
                </div>
            </nav>
        </>
    );
};

export default DocuwizToolbar;

