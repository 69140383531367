import React, {useState} from 'react';
import {Button, DatePicker, Table, Tag, theme} from "antd";
import {ColumnsType} from "antd/es/table";
import {QueryKey, useQueryClient} from "@tanstack/react-query";
import {useCommonUseQuery} from "../../../common/hooks/rest/ReacQueryHook";
import Search from "antd/es/input/Search";
import {DocumentOption, procGetDocumentList} from "../../../rest/admin/DocumentMgr";
import {DocumentList} from "../../index/content/Viewer/data/DocumentList";
import {formatDate, getFirstDayOfMonth, getToday} from "../../../common/util/DateUtil";
import UserAvatar from "../../index/header/UserAvatar/UserAvatar";
import {bytesToFileSizeString} from "../../../common/util/FileUtil";
import dayjs from "dayjs";

const FileMgrDocument: React.FC = () => {
    const { token: { colorBgContainer },} = theme.useToken();
    const { RangePicker } = DatePicker;
    const [docuOpt, setDocuOpt] = useState<DocumentOption>(
        {
            documentId:null,
            documentName:null,
            userId:null,
            userName:null,
            startRegdate:getFirstDayOfMonth(),
            endRegdate:getToday()
        }
    )
    const queryClient = useQueryClient();
    const queryKey:QueryKey =   [`DocumentMgr`];
    const { data} = useCommonUseQuery<DocumentList[]>(
        queryKey,
        () =>procGetDocumentList(docuOpt));

    const onSearch = (value: string) => console.log(value);


    const columns: ColumnsType<DocumentList> = [
        {
            title: '등록자',
            dataIndex: 'userName',
            key: 'userName',
            render: (text: string) =>
                <div style={{ paddingBottom: '9px', margin:'0', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <UserAvatar userName={text}/>
                </div>
            ,
        },
        {
            title: '문서명',
            dataIndex: 'docName',
            key: 'docName',
            render: (text: string) =>
                <div style={{ paddingBottom: '9px', margin:'0', display: 'flex', alignItems: 'left', justifyContent: 'left' }}>
                    <a>{text}</a>
                </div>
            ,
        },
        {
            title: '버전',
            dataIndex: 'fileVersion',
            key: 'fileVersion',
            render: (text: string,record:DocumentList) =>
                <div style={{ paddingBottom: '9px', margin:'0', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    {text}.0
                </div>
            ,
        },
        {
            title: '파일크기',
            dataIndex: 'fileSize',
            key: 'fileSize',
            render: (text: number, record:DocumentList) =>
                <div style={{ paddingBottom: '9px', margin:'0', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    {bytesToFileSizeString(text)}
                </div>
            ,
        },
        {
            title: '업로드날짜',
            key: 'createTime',
            dataIndex: 'createTime',
            render: (text: string, record:DocumentList) =>
                <div style={{ paddingBottom: '9px', margin:'0', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    {formatDate(text)}
                </div>
            ,
        },
        {
            title: '수정날짜',
            key: 'modifyTime',
            dataIndex: 'modifyTime',
            render: (text: string, record:DocumentList) =>
                <div style={{ paddingBottom: '9px', margin:'0', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    {formatDate(text)}
                </div>
            ,
        },
        {
            title: '',
            key: 'Active',
            dataIndex: 'Active',
            render: (text, record:DocumentList) =>
                <div style={{ paddingBottom: '9px', margin:'0', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Button type="link" size="small">히스토리</Button>
                    <Button type="link" size="small">다운로드</Button>
                    <Button type="link" size="small">삭제</Button>
                </div>
            ,
        },
    ];

    return (
        <>
        <nav style={{
            margin:'0px 0px 14px 0px',
            padding:'9px 8px 9px 0px',
            height: '50px',
            display:'flex',
            justifyContent: "space-between",
            alignItems:'center',
            borderRadius: "8px", // 라운드 처리 추가
            border: '1px solid #dadada',
            background: colorBgContainer,
        }}>
            <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{
                    padding: '9px 5px',
                    display:'flex',
                }}>
                    <RangePicker
                        defaultValue={[dayjs(docuOpt.startRegdate, 'YYYYMMDD'), dayjs(docuOpt.endRegdate, 'YYYYMMDD')]}
                        format={'YYYYMMDD'}
                    />


                </div>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
                <Search placeholder="검색어를 입력해보세요." allowClear onSearch={onSearch} style={{ width: 210 }} /> <Button type="primary" size="small">조회</Button>
            </div>
        </nav>
        <Table columns={columns}
               dataSource={data}
               rowKey="docId"
        />
        </>
    );
};

export default FileMgrDocument;
