import { createContext } from "react";
import {ViewerOption} from "../data/ViewerOption";
interface ViewerOptionContextType {
    viewerOption: ViewerOption | null;
    setViewerOption: (value: ViewerOption) => void;
}

const defaultViewerOptionContextValue: ViewerOptionContextType = {
    viewerOption: null,
    setViewerOption: () => {}, // 기본 빈 함수를 제공합니다.
};

const ViewerOptionContext = createContext<ViewerOptionContextType>(defaultViewerOptionContextValue);
export default ViewerOptionContext;