import axiosInstance from "../../common/util/AxiosInstance";
import {DocumentList} from "../../component/index/content/Viewer/data/DocumentList";


/*
export async function  procSelectFile(data:any, pageParam:number):Promise<DocumentList[]>{

    const selectUrl:string = 'rest/docuwiz/document/select';

    const page  =   pageParam === null ? 1: pageParam;
    const response = await axiosInstance.request({
        method:`GET`,
        url: page > 0 ?  `${selectUrl}/${page}` : `${selectUrl}`,
        headers: {
            'Content-Type': 'application/json'
        },
        params:    data
    });
    return response.data;
};
*/
export async function  procSelectFile(data:any):Promise<DocumentList[]>{

    const selectUrl:string = 'rest/docuwiz/document/select/list';

    const response = await axiosInstance.request({
        method:`GET`,
        url: selectUrl,
        headers: {
            'Content-Type': 'application/json'
        },
        params:    data
    });
    return response.data;
};

export async function  procDeleteFile(data:any):Promise<DocumentList[]>{

    const deleteUrl:string = 'rest/docuwiz/document/delete';

    const response = await axiosInstance.request<DocumentList[]>(
        {
            method: "DELETE",
            url: deleteUrl,
            headers: {
                'Content-Type': 'application/json',
            },
            data:   data
        });

    return response.data;
};

export async function  procUploadFile(data:any):Promise<DocumentList[]>{

    const uploadUrl:string = 'rest/docuwiz/document/upload';

    const response = await axiosInstance.request<DocumentList[]>(
        {
            method: "POST",
            url: uploadUrl,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            data:   data
        });

    return response.data;
};