import React from 'react';
import {Select, Table, theme} from "antd";
import {ColumnsType} from "antd/es/table";
import {QueryKey, useQueryClient} from "@tanstack/react-query";
import {useCommonUseQuery} from "../../../common/hooks/rest/ReacQueryHook";
import {formatDate} from "../../../common/util/DateUtil";
import {
    procGetStorageWizStorageInfraList,
    procGetStorageWizStorageList,
    procGetStorageWizUrlList,
    TB_STORAGE_MGR, TB_STORAGE_OPTION,
    TB_STORAGEWIZ
} from "../../../rest/admin/StorageWizMgr";

const StorageInfra: React.FC = () => {
    const { token: { colorBgContainer },} = theme.useToken();
       const queryClient = useQueryClient();
    const queryKey:QueryKey =   [`storage/infra`];
    const { data} = useCommonUseQuery<TB_STORAGE_OPTION[]>(
        queryKey,
        () =>procGetStorageWizStorageInfraList());

    const handleChange = (value: string) => {
        console.log(`selected ${value}`);
    };
    const columns: ColumnsType<TB_STORAGE_OPTION> = [
        {
            title: 'Index',
            dataIndex: 'id',
            key: 'id',
            render: (id: number) =>
                <div style={{ paddingBottom: '9px', margin:'0', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    {id}
                </div>
            ,
        },
        {
            title: '클라우드 종류',
            dataIndex: 'activeClassName',
            key: 'activeClassName',
            render: (text: string) =>
                <div style={{ paddingBottom: '9px', margin:'0', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Select
                        defaultValue={text}
                        style={{ width: 200 }}
                        onChange={handleChange}
                        options={[
                            { value: 'LOCALTASK', label: 'Hard Disk' },
                            { value: 'SMBTASK', label: 'SMB' },
                            { value: 'FTPTASK', label: 'FTP' },
                            { value: 'AMAZONTASK', label: 'Amazon S3' },
                            { value: 'ORACLETASK', label: 'Oracle Object Storage' },
                        ]}
                    />
                </div>
            ,
        },
        {
            title: '보안저장',
            dataIndex: 'isAesEnabled',
            key: 'isAesEnabled',
            render: (text: boolean) =>
                <div style={{ paddingBottom: '9px', margin:'0', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    {text ? 'Y' : 'N'}
                </div>
            ,
        },
        {
            title: '생성일자',
            key: 'lastRegTime',
            dataIndex: 'lastRegTime',
            render: (text: string) =>
                <div style={{ paddingBottom: '9px', margin:'0', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    {formatDate(text)}
                </div>
            ,
        },

    ];

    return (
        <>
        <Table columns={columns}
               dataSource={data}
               rowKey="jwtKey"
        />
        </>
    );
};

export default StorageInfra;
