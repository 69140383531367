import React, {CSSProperties, useContext, useState} from "react";
import "../FileInfo/css/FileInfo.css"
import UserAvatar from "../../../header/UserAvatar/UserAvatar";
import {formatDate} from "../../../../../common/util/DateUtil";
import CommentInput from "./CommentInput";
import {CommentData} from "../data/CommentData";
import ViewerOptionContext from "../../../../../context/ViewerOptionContext";
import {QueryKey, useQueryClient} from "@tanstack/react-query";
import {useCommonUseMutation} from "../../../../../common/hooks/rest/ReacQueryHook";
import {procDeleteCommentData} from "../../../../../rest/comment/Comment";
import {Modal} from "antd";
import ViewerDetailContext from "../../../../../context/ViewerDetailContext";

interface CommentContentProps extends CommentData
{
    encDocId:string,
    showReplyCommentId:string,
    setShowReplyCommentId: (value: string) => void;
}

const CommentContent = (props:CommentContentProps) => {
    const { viewerOption } = useContext(ViewerOptionContext);
    const { setRefetchViewer } = useContext(ViewerDetailContext);
    const [modifyInput, setModifyInput] = useState<boolean>(false);
    const [showDeleteBox, setShowDeleteBox] = useState<boolean>(false);
    const queryClient = useQueryClient();
    const queryKey:QueryKey =   [`comment_${props.encDocId}`];

    const mutateDelete = useCommonUseMutation<boolean>(queryKey, queryClient, procDeleteCommentData);

    function deleteComment()
    {
        setShowDeleteBox(true);
        Modal.confirm({
            title: '코멘트를 삭제하시겠습니까?',
            content: "삭제 후에는 복구되지 않습니다.",
            open: showDeleteBox,
            okText: "확인",
            cancelText: "취소",
            async onOk() {
                const delData = {
                    delCommentId : props.commentId,
                    delUserId : viewerOption?.jwtSession?.userId
                }
                setRefetchViewer(true);
                mutateDelete.mutateAsync(delData ).then();
            },
            onCancel() {
                setShowDeleteBox(false);
            },
        });
    }

    function showCmmtInput()
    {
        props.setShowReplyCommentId(props.commentId as string);
    }

    function showModify()
    {
        setModifyInput(true);
    }

    const reply: CSSProperties = {
        display: "flex",
        flexDirection: "column",
        marginTop:'10px',
        borderBottom:'1px solid lightgrey',
        marginLeft:'15px',
    };

    const noReply: CSSProperties = {
        display: "flex",
        flexDirection: "column",
        marginTop:'10px',
        borderBottom:'1px solid lightgrey',

    };

    return (
        <div
            style={props.parentCommentId ? reply :noReply }
        >
            <div
                style={{
                    display: "flex",
                    flex: 1,
                }}
            >
                <div style={{
                    width: "40px"
                }}>
                    <UserAvatar userName={props.userName as string} avatarSize="default"/>
                </div>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        flex: 1,
                    }}
                >
                    <div style={{
                        flex: 1,
                        fontSize:'12px',
                        fontWeight:"bold",
                    }}>
                        { props.userName }
                    </div>
                    <div style={{
                        flex: 1,
                        fontSize:'11px',
                    }}>
                        { formatDate(props.lastRegTime as string) }
                    </div>
                </div>
            </div>
            <div style={{
                flex: 1,
                fontSize:'13px',
                paddingLeft:'3px',
                paddingRight:'3px',
                paddingTop:'3px',
                overflowX:'hidden',
                overflowWrap:'break-word',
                whiteSpace:'pre-line'
            }}>
                {modifyInput ?
                    <CommentInput mode="modify"
                                  docId={props.encDocId}
                                  commentId={props.commentId}
                                  setShowReplyCommentId={props.setShowReplyCommentId}
                                  commentTxt={props.comment}
                                  setModifyInput={setModifyInput}
                    />
                    :
                    props.parentCommentId ? <><b>{props.targetUserName}</b> {props.comment}</> : <>{props.comment}</>
                }
            </div>
            <div style={{
                flex: 1,
                fontSize:'13px',
                paddingLeft:'3px',
                paddingRight:'3px',
                paddingTop:'10px',
                marginBottom:'10px',
            }}>
                <span
                    onClick={showCmmtInput}
                    style={{
                    color:'#1677ff',
                    marginRight:"8px",
                    cursor:'pointer'
                }}>답글</span>
                { props.userId === viewerOption?.jwtSession?.userId &&
                    <>
                <span
                    onClick={showModify}
                    style={{
                        color:'#1677ff',
                        marginRight:"8px",
                        cursor:'pointer'
                    }}>수정</span>
                    <span
                        onClick={deleteComment}
                        style={{
                        color:'#1677ff',
                        cursor:'pointer'
                    }}>삭제</span>
                    </>
                }
            </div>
            {(props.commentId === props.showReplyCommentId) &&
                <CommentInput mode="reply"
                              docId={props.encDocId}
                              parentCommentId={props.parentCommentId ? props.parentCommentId : props.commentId}
                              targetUserId={props.userId}
                              targetUserName={props.userName}
                              setShowReplyCommentId={props.setShowReplyCommentId}/>
            }
        </div>
    );
};

export default CommentContent;