import React from 'react';
import {Avatar, Dropdown, MenuProps, message} from 'antd';
import {ClearOutlined} from "@ant-design/icons";
import {AvatarSize} from "antd/es/avatar/SizeContext";
import {stringToColor} from "../../../../common/util/ColorUtil";

interface UserAvatarProps {
    userName:string,
    avatarSize?:AvatarSize,
}


const UserAvatar = (props:UserAvatarProps) => {
    const avatarName = (userName: string):string =>
    {
        const spaceIndex = userName.indexOf(' ');

        // If a space is found, return the substring before the first space
        if (spaceIndex !== -1) {
            return userName.substring(0, spaceIndex);
        }

        // If there's no space, remove the first character and return the rest
        return userName.substring(1);
    }


    const items:MenuProps['items'] = [
        {
            key: 'CHANGE_THEME',
            label: '테마',
            icon: <ClearOutlined />,
        },
    ];
    const handleMenuClick: MenuProps['onClick'] = (e) => {

        const key:string = e.key;
        message.info(`${key} Click on menu item.`);
        console.log('click', e);

    };

    const menuProps = {
        items,
        onClick: handleMenuClick,
    };

    const getAvataSize = ():AvatarSize =>
    {
        let avatarSize:AvatarSize = "large";
        if(props.avatarSize != null)
        {
            avatarSize = props.avatarSize;
        }

        return avatarSize;
    }
    return (

            <Avatar style={{ backgroundColor: stringToColor(props.userName), verticalAlign: 'middle', cursor:'pointer' }} size={getAvataSize()}>
                <b>{avatarName(props.userName)}  </b>
            </Avatar>
    );

    /*
    return (

        <Dropdown
            placement="bottomRight"
            menu={menuProps}
        >

            <Avatar style={{ backgroundColor: stringToColor(props.userName), verticalAlign: 'middle', cursor:'pointer' }} size={getAvataSize()}>
                <b>{avatarName(props.userName)}  </b>
            </Avatar>
        </Dropdown  >
    );

     */
};

export default UserAvatar;