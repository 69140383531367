import React, {CSSProperties, useContext, useEffect, useState} from 'react';
import {Badge, Button, Table, Tooltip} from 'antd';

import EmptyCompnt from "../EmptyCompnt/EmptyCompnt";
import { FileListOption } from "../data/FileListOption";
import {TableRowSelection} from "antd/es/table/interface";
import './css/FileList.css'
import FileAvatar from "./FileAvatar";
import useDownloadFile from "../../../../../common/hooks/rest/useDownloadFile"
import RightButtonMenu from "../RightButtonMenu/RightButtonMenu";
import {DocumentList} from "../data/DocumentList";
import ViewerOptionContext from "../../../../../context/ViewerOptionContext";
import DocuwizViewerContext from "../../../../../context/DocuwizViewerContext";
import {CommentOutlined, DeleteOutlined, DownloadOutlined, EyeOutlined, InfoCircleOutlined} from "@ant-design/icons";
import useWindowSize from "../../../../../common/hooks/useWindowSize";
import ViewerDetailContext from "../../../../../context/ViewerDetailContext";

interface FileListProps extends FileListOption{
    onDeleteFile(fileName:string, docId:string) :void;
}

const FileListCompnt: React.FC<FileListProps> = ({
                                                      isLoading,
                                                      flattenedData,
                                                      viwerRef,
                                                     onDeleteFile,
                                                  }) => {

    const { viewerOption } = useContext(ViewerOptionContext);


    const docuwizContext = useContext(DocuwizViewerContext);
    const {checkedItem, setCheckedItem, setIndeterminate,setAllSelected} = docuwizContext;

    const { width } = useWindowSize();

    const downloadFile = useDownloadFile();
    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        console.log('selectedRowKeys changed: ', newSelectedRowKeys);
        setCheckedItem(newSelectedRowKeys);
        const allChecked:boolean = flattenedData.length > 0 && newSelectedRowKeys.length === flattenedData.length;
        const indeterminate:boolean = newSelectedRowKeys.length > 0 && newSelectedRowKeys.length < flattenedData.length;


        setAllSelected(allChecked);
        setIndeterminate(indeterminate);

    };

    const contentWrapperStyle: CSSProperties = {
        width: '100%',
        transition: 'width 0.3s ease-in-out',
    };

    const { showSideSlide, setShowSideSlide,  setFileInfoData, setShowComment } = useContext(ViewerDetailContext);

    const columns = [
        {
            title: '종류',
            dataIndex: 'extension',
            key: 'extension',
            width :60,
            render: (text: string,  record:DocumentList) =>
                <div style={{ padding: '0', margin:'0', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <FileAvatar extension={text} encryptInterfaceId={viewerOption?.encryptInterfaceId} docId={record.docId}/>
               </div>

            ,
        },
        {
            title: '파일명',
            dataIndex: 'fileName',
            key: 'fileName',
            render: (text:string, record:DocumentList) => (
                <div>
                <div className="col_filename">
                    <span
                        className="link-style-button"
                        onClick={(e) => {
                            e.preventDefault(); // 기본 링크 동작을 막습니다.
                            console.log('Checkbox value:', record.docId); // 체크박스 값 (해당 행의 key) 출력
                            const url:string = "rest/docuwiz/document/download";
                            const docId:string = record.docId;
                            const docVersion:number = record.fileVersion;
                            const defFileName:string = text;
                            downloadFile({
                                url: url,
                                docId: docId,
                                defFileName: defFileName,
                                docVersion: docVersion
                            }).then();
                        }}
                    >
                        <b>{text}</b>
                    </span>
                </div>
                    <div className="col_filename2">
                        { (!showSideSlide && width > 350) && (
                            <div className="col_filename2 left">{record.userName}</div>
                        )}

                        <div className="col_filename2 right" style={{width: (!showSideSlide && width > 350)  ? '75%' : '100%'}}>

                            {width > 350  &&
                                <Tooltip title="미리보기" arrow={false}>
                                <Button className="fileListRightBtn" shape="circle" size="small" icon={<EyeOutlined />} onClick={(e) => {
                                    e.stopPropagation();
                                    const popUpOptions:string = "toolbar=no,scrollbars=no,resizable=yes,status=no,menubar=no,width=1200, height=800, top=0,left=0";
                                    window.open("../../view/"+viewerOption?.encryptInterfaceId+"/"+record.docId, viewerOption?.encryptInterfaceId, popUpOptions);
                                }}/>
                                </Tooltip>
                            }

                            <Tooltip title="코멘트 보기" arrow={false}>
                                <Badge size="small" dot={record.commentCount>0 ? true :false}>

                                    <Button className="fileListRightBtn" shape="circle" size="small" icon={<CommentOutlined />} onClick={(e) => {
                                        e.stopPropagation();
                                        setFileInfoData(record);
                                        setShowComment(true);
                                        setShowSideSlide(true);
                                    }}/>

                                </Badge>
                            </Tooltip>
                            <Tooltip title="파일 다운로드" arrow={false}>
                                <Button className="fileListRightBtn" shape="circle" size="small" icon={<DownloadOutlined />} onClick={(e) => {
                                    e.stopPropagation();
                                    const url:string = "rest/docuwiz/document/download";
                                    const docId:string = record.docId;
                                    const docVersion:number = record.fileVersion;
                                    const defFileName:string = record.fileName;
                                    downloadFile({
                                        url: url,
                                        docId: docId,
                                        defFileName: defFileName,
                                        docVersion: docVersion
                                    }).then();}}/>
                            </Tooltip>
                            <Tooltip title="파일 세부정보" arrow={false}>
                            <Button className="fileListRightBtn" shape="circle" size="small" icon={<InfoCircleOutlined />} onClick={ () => {
                                setFileInfoData(record);
                                setShowComment(false);
                                setShowSideSlide(true);
                            }}/>
                            </Tooltip>
                            <Tooltip title="파일 삭제" arrow={false}>
                                <Button className="fileListRightBtn" shape="circle" size="small" icon={<DeleteOutlined />} onClick={ () => {onDeleteFile(record.fileName, record.docId)}}/>
                            </Tooltip>
                        </div>
                    </div>
                </div>
            ),
        },
        {
            title: '용량',
            dataIndex: 'formatFileSize',
            key: 'formatFileSize',
            width:70,
            render: (text: string) =>
                <div style={{ padding: '0', margin:'0', display: 'flex', alignItems: 'right', justifyContent: 'right' }}>
                    {text}
                </div>

            ,
        },
        {
            title: '등록일시',
            dataIndex: 'formatCreateTime',
            key: 'formatCreateTime',
            width:90,
            render: (text: string) =>
                <>
                <div style={{ fontSize:'12px', padding: '0', margin:'0', display: 'flex', alignItems: 'right', justifyContent: 'right' }}>
                    {text.split(';')[0]}
                </div>
                <div style={{ padding: '0', margin:'0', display: 'flex', alignItems: 'right', justifyContent: 'right' }}>
                    {text.split(';')[1]}
                </div>
                </>
            ,
        },
    ];

    const [tbcolumns, setTbColumns] = useState(columns);

    const getColumnProps = () => {
        const baseProps = columns;
        let isMobile =  width < 448;
        let mobileProps         =   baseProps;
        if (isMobile)
        {
            if (width >= 400)
            {
                mobileProps = columns.slice(0,3);
            }
            else if (width >= 350)
            {
                mobileProps = columns.slice(0,2);
            }
            else
            {
                mobileProps = columns.slice(0,2);
            }
        }
        if (showSideSlide) {
            return isMobile ? columns.slice(1,2) : columns.slice(0,2);
        }

        return isMobile ? mobileProps : baseProps;
    };

    useEffect(() => {
        setTbColumns(getColumnProps());
    }, [showSideSlide]);





    const rowSelection: TableRowSelection<DocumentList> = {
        selectedRowKeys: checkedItem as React.Key[],
        onChange: onSelectChange,
        /*selections: [
            {
                key: 'all',
                text: '전체 선택',
                onSelect: (changeableRowKeys) => {
                    let newSelectedRowKeys = [];
                    newSelectedRowKeys = changeableRowKeys.filter((_, index) => {
                        return true;
                    });
                    setCheckedItem(newSelectedRowKeys);
                },
            },

            {
                key: 'clear',
                text: '전체 선택 해제',
                onSelect: (changeableRowKeys) => {
                    let newSelectedRowKeys = [];
                    newSelectedRowKeys = changeableRowKeys.filter((_, index) => {
                        return false;
                    });
                    setCheckedItem(newSelectedRowKeys);
                },
            },
           {
                key: 'odd',
                text: '홀수 선택',
                onSelect: (changeableRowKeys) => {
                    let newSelectedRowKeys = [];
                    newSelectedRowKeys = changeableRowKeys.filter((_, index) => {
                        if (index % 2 !== 0) {
                            return false;
                        }
                        return true;
                    });
                    setCheckedItem(newSelectedRowKeys);
                },
            },
            {
                key: 'even',
                text: '짝수 선택',
                onSelect: (changeableRowKeys) => {
                    let newSelectedRowKeys = [];
                    newSelectedRowKeys = changeableRowKeys.filter((_, index) => {
                        if (index % 2 !== 0) {
                            return true;
                        }
                        return false;
                    });
                    setCheckedItem(newSelectedRowKeys);
                },
            },


            {
                key: 'invert',
                text: '선택 해제',
                onSelect: (changeableRowKeys) => {
                    let newSelectedRowKeys = [];
                    newSelectedRowKeys = changeableRowKeys.filter((_, index) => {
                        if (!checkedItem.includes(changeableRowKeys[index]))
                        {
                            return true;
                        }

                    });
                    setCheckedItem(newSelectedRowKeys);
                },
            },
        ],

         */
    };


    const [curData, setCurData] = useState<DocumentList>();

    const handleContextMenu = (event: React.MouseEvent, record: DocumentList) => {
        setCurData(record);
        event.preventDefault();
    };

    return (
        <RightButtonMenu curData={curData}
                         setCurData={setCurData}
                         dataComponent={
                             <div
                                 id="FileList"
                                 ref={viwerRef}
                                 style={{
                                     ...contentWrapperStyle,
                                     width: showSideSlide ? '63%': '100%',
                                     height: '100%',
                                     overflowY: 'hidden',
                                     overflowX: 'hidden',
                                 }}
                             >
                                 {flattenedData.length > 0 && !isLoading ? (
                                         viewerOption?.mode==="rw" ? (
                                                 <Table
                                                     style={{  fontSize: '1'}}
                                                     rowSelection={rowSelection}
                                                     dataSource={flattenedData}
                                                     columns={tbcolumns}
                                                     pagination={false}
                                                     rowKey="docId"
                                                     scroll={{ y: 'calc(100vh - 170px)' }}
                                                     onRow={(record) => {
                                                         return {
                                                             onContextMenu: (event) => {
                                                                 handleContextMenu(event, record);
                                                             },
                                                         };
                                                     }}
                                                 />
                                         ) : (
                                             <Table
                                                 style={{  fontSize: '1'}}
                                                 dataSource={flattenedData}
                                                 columns={tbcolumns}
                                                 pagination={false}
                                                 rowKey="docId"
                                                 scroll={{ y: 'calc(100vh - 170px)' }}
                                                 onRow={(record) => {
                                                     return {
                                                         onContextMenu: (event) => {
                                                             handleContextMenu(event, record);
                                                         },
                                                     };
                                                 }}
                                             />
                                         )
                                 ) : (
                                     <EmptyCompnt />
                                 )}


                             </div>
                         }
        />

    );
};

export default FileListCompnt;
/*

<div
    id="FileList"
    ref={viwerRef}
    style={{
        width: '100%',
        height: '100%',
        overflowY: 'hidden',
        overflowX: 'hidden',
    }}
>
    <InfiniteScroll
        style={{
            overflowX: 'hidden',
            overflowY: 'auto',
        }}
        dataLength={flattenedData.length || 0}
        next={handleLoadMore}
        hasMore={!isFetchingNextPage}
        loader={<></>}
        scrollableTarget="FileList"
    >
        {flattenedData.length > 0 ? (
            <Table
                dataSource={flattenedData}
                columns={columns}
                pagination={false}
                rowKey="fileId"
                scroll={{ y: 'calc(100vh - 17px)' }}
            />
        ) : (
            <EmptyCompnt />
        )}
    </InfiniteScroll>
</div>

 */