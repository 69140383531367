export function formatDate(dateString: string): string {
    const date = new Date(dateString);

    const yyyy = date.getFullYear();
    const MM = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based in JavaScript
    const dd = String(date.getDate()).padStart(2, '0');
    const hh = String(date.getHours()).padStart(2, '0');
    const mm = String(date.getMinutes()).padStart(2, '0');
    const ss = String(date.getSeconds()).padStart(2, '0');

    return `${yyyy}.${MM}.${dd} ${hh}:${mm}:${ss}`;
}

export function getCurrentDateTime(): string {
    const date = new Date();
    return makeDateTimeFormat(date);
}

export function getYesterdayDateTime(): string {
    const date = new Date();
    date.setDate(date.getDate() - 1); // Subtract one day
    return makeDateTimeFormat(date);
}

export function getFirstDayOfMonth(): string {
    let date = new Date();
    date.setDate(1);
    return makeDateFormat(date);
}

export function getToday(): string {
    let date = new Date();
    return makeDateFormat(date);
}

function makeDateTimeFormat(date : Date)
{
    const year = date.getFullYear();
    const month = date.getMonth() + 1; // getMonth() is zero-based
    const day = date.getDate();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();

    // Padding single digits with 0
    const monthString = month < 10 ? '0' + month : '' + month;
    const dayString = day < 10 ? '0' + day : '' + day;
    const hoursString = hours < 10 ? '0' + hours : '' + hours;
    const minutesString = minutes < 10 ? '0' + minutes : '' + minutes;
    const secondsString = seconds < 10 ? '0' + seconds : '' + seconds;

    return `${year}-${monthString}-${dayString} ${hoursString}:${minutesString}:${secondsString}`;
}

function makeDateFormat(date : Date)
{
    let year = date.getFullYear();
    let month = date.getMonth() + 1; // getMonth() is zero-based
    let day = date.getDate();

    // Padding month or day with 0 if they are single digit
    let monthString = month < 10 ? '0' + month : '' + month;
    let dayString = day < 10 ? '0' + day : '' + day;

    return '' + year + monthString + dayString;
}