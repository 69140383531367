import axiosInstance from "../../common/util/AxiosInstance";
import {ViewerOption} from "../../data/ViewerOption";
import {JwtToken} from "../../common/types/JwtToken";
import {JwtSession} from "../../common/types/JwtSession";

export interface LoginParam {
    param:string,
    requestPageSize:number
}

export interface LoginResponse {
    jwtSession:JwtSession,
    jwtToken:JwtToken
    viewerOption:ViewerOption
}


export async function  procLogin(loginsvc:string, requestParam:LoginParam):Promise<LoginResponse>{

    const response = await axiosInstance.request<LoginResponse>(
        {
            method: "POST",
            url: `rest/login/jwt/authenticate/${loginsvc}`,
            headers: {
                'Content-Type': 'application/json',
            },
            data:   requestParam
        });

    return response.data;
};