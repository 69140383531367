// SlideDrawer.tsx
import React, {CSSProperties, useContext} from 'react';
import { CloseOutlined } from '@ant-design/icons';
import FileInfoContent from "../FileInfo/FileInfoContent";
import ViewerDetailContext from "../../../../../context/ViewerDetailContext";
import CommentList from "../Comment/CommentList";

const slideDrawerStyle: CSSProperties = {
    position: 'fixed',
    right: 0,
    top: 0,
    width: '37%',
    height: '100vh',
    background: '#ffffff',
    boxShadow: '-5px 0 15px rgba(0, 0, 0, 0.1)',
    transition: 'transform 0.3s ease-in-out',
};

const closedSlideDrawerStyle: CSSProperties = {
    ...slideDrawerStyle,
    transform: 'translateX(100%)',
};

const closeButtonStyle: CSSProperties = {
    position: 'absolute',
    right: '10px',
    top: '10px',
    cursor: 'pointer',
};

const SlideDrawer = () => {

    const { showSideSlide, setShowSideSlide, fileInfoData
           ,showComment} = useContext(ViewerDetailContext);

    return (
        <div style={ showSideSlide ? slideDrawerStyle : closedSlideDrawerStyle}>
            {showSideSlide && (
                <CloseOutlined
                    style={closeButtonStyle}
                    onClick={() => setShowSideSlide(false)}
                />
            )}

            {fileInfoData &&
                ( showComment ? <CommentList fileInfo={fileInfoData}/> : <FileInfoContent fileInfo={fileInfoData}/> )
            }
        </div>
    );
};

export default SlideDrawer;