import React, {useContext} from 'react';
import {Radio, RadioChangeEvent, Tooltip} from 'antd';
import {AppstoreOutlined, BarsOutlined} from "@ant-design/icons";
import DocuwizViewerContext from "../../../../../context/DocuwizViewerContext";

const SlectListType = () => {

    const docuwizContext = useContext(DocuwizViewerContext);
    const {setListMode, listMode} = docuwizContext;
    const handleChange = (e: RadioChangeEvent) => {
        setListMode(e.target.value);
    };

    return (
        <Radio.Group
            onChange={handleChange}
            value={listMode}
            buttonStyle="solid"
            optionType="button"
        >
            <Tooltip title="이미지로 보기" arrow={false}>
                <Radio.Button value="Thumb">
                    <AppstoreOutlined />
                </Radio.Button>
            </Tooltip>
            <Tooltip title="목록으로 보기" arrow={false}>
                <Radio.Button value="File" >
                    <BarsOutlined />
                </Radio.Button>
            </Tooltip>
        </Radio.Group>
    );
};

export default SlectListType;