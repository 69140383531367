import React from 'react';
import {Button, Tooltip} from 'antd';

interface FileUploadButtonProps {
    iconComponent: React.ReactNode;
    fileTagId: string;

    tooltip:string;
}

const FileUploadButton: React.FC<FileUploadButtonProps> = ({ iconComponent, fileTagId, tooltip }) => {
    return (
        <Tooltip title={tooltip} arrow={false}>
            <Button
                type="primary"
                style={{ padding: '0' }}
                icon={
                    <label htmlFor={fileTagId} style={{ cursor: 'pointer', display: 'inline-flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%' }}>
                        {iconComponent}
                    </label>
                }
            />
        </Tooltip>
    );
};

export default FileUploadButton;