import React, {CSSProperties, useContext, useEffect, useState} from 'react';
import {Card, Checkbox, Col,  Row, Tooltip} from 'antd';
import EmptyCompnt from "../EmptyCompnt/EmptyCompnt";
import {FileListOption} from "../data/FileListOption";
import {
    DownloadOutlined,
    EyeOutlined,
    InfoCircleOutlined,
} from "@ant-design/icons";
import "./css/ThumbList.css"
import useDownloadFile from "../../../../../common/hooks/rest/useDownloadFile";
import RightButtonMenu from "../RightButtonMenu/RightButtonMenu";
import ThumbContainer from "./ThumbContainer";
import ViewerOptionContext from "../../../../../context/ViewerOptionContext";
import DocuwizViewerContext from "../../../../../context/DocuwizViewerContext";
import {DocumentList} from "../data/DocumentList";
import useWindowSize from "../../../../../common/hooks/useWindowSize";
import ViewerDetailContext from "../../../../../context/ViewerDetailContext";

interface ThumbListProps extends FileListOption{
    onDeleteFile(fileName:string, docId:string) :void;
}
const ThumbListCompnt: React.FC<ThumbListProps> = ({
                                                       isLoading,
                                                       flattenedData,
                                                       viwerRef,
                                                       onDeleteFile
                                                   }) => {

   const { viewerOption } = useContext(ViewerOptionContext);

    const docuwizContext = useContext(DocuwizViewerContext);
    const {checkedItem, setCheckedItem, allSelected, setAllSelected, setIndeterminate,indeterminate} = docuwizContext;


    const { width } = useWindowSize();

    const { showSideSlide, setShowSideSlide, setFileInfoData, setShowComment } = useContext(ViewerDetailContext);

    const onCheckBoxChange = (docId:string)=>
    {

        let newCheckedItems: React.Key[];
        if (checkedItem?.includes(docId)) {
            newCheckedItems = checkedItem.filter((id) => id !== docId);
        } else {
            newCheckedItems = [...checkedItem as React.Key[], docId];
        }

        setCheckedItem(newCheckedItems);

        const allChecked = flattenedData.length > 0 && newCheckedItems.length === flattenedData.length;
        const indeterminate = newCheckedItems.length > 0 && newCheckedItems.length < flattenedData.length;

        setAllSelected(allChecked);
        setIndeterminate(indeterminate);
    }




    const downloadFile = useDownloadFile();


    useEffect(() => {
        if (allSelected) {
            const allKeys = flattenedData.map((item) => item.docId);
            setCheckedItem(allKeys);
            setIndeterminate(false);
        } else if (!indeterminate) {
            setCheckedItem([]);
            setIndeterminate(false)
        }
    }, [allSelected]);
    const [curData, setCurData] = useState<DocumentList>();

    const contentWrapperStyle: CSSProperties = {
        width: '100%',
        transition: 'width 0.3s ease-in-out',
    };

    const getColProps = () => {
        const baseProps = { xs: 12, sm: 8, md: 6, lg: 6, xl: 4, xxl: 3 };
        const baseDivideProps = {
            xs: baseProps.xs * 2,
            sm: baseProps.sm * 2,
            md: baseProps.md * 2,
            lg: baseProps.lg * 2,
            xl: baseProps.xl * 2,
            xxl: baseProps.xxl * 2,
        };

        let isMobile =  width < 448;
        let mobileProps         =   baseProps;
        if (isMobile)
        {
            if (width >= 350)
            {
                mobileProps = baseProps;
            }
            else
            {
                mobileProps = baseDivideProps;
            }
        }

        if (showSideSlide) {
            return baseDivideProps;

        }

        return isMobile ? mobileProps : baseProps;
    };


    return (
       <RightButtonMenu curData={curData} setCurData={setCurData}
                        dataComponent={
        <div
            id="FileList"
            ref={viwerRef}
            style={{
                ...contentWrapperStyle,
                width: showSideSlide ? '63%' : '100%',
                height: '100%',
                overflowY: 'auto',
                overflowX: 'hidden',
                padding: '16px 16px',
                position:'relative',
            }}
        >
        {
            flattenedData.length > 0 ?
                <Checkbox.Group
                    style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column',  }}
                    value={checkedItem as React.Key[]}
                >
                    <Row gutter={[16, 16]}>
                        {flattenedData.map((item: DocumentList) => (
                            <Col key={item.docId} {...getColProps()}>
                                <Card
                                    hoverable
                                    loading={isLoading}
                                    style={{ maxWidth:'220px',border : checkedItem?.includes(item.docId) ? '2px solid #25A5EC' : '1px solid #25A5EC' }}
                                    actions={[
                                        <Tooltip title="미리보기" key="edit" arrow={false}>
                                            <EyeOutlined  onClick={(e) => {
                                                e.stopPropagation();
                                                const popUpOptions:string = "toolbar=no,scrollbars=no,resizable=yes,status=no,menubar=no,width=1200, height=800, top=0,left=0";
                                                window.open("../../view/"+viewerOption?.encryptInterfaceId+"/"+item.docId, viewerOption?.encryptInterfaceId, popUpOptions);

                                            }} />
                                        </Tooltip >,
                                        <Tooltip title="다운로드" key="edit" arrow={false}>
                                            <DownloadOutlined onClick={(e) => {
                                                e.stopPropagation();
                                                const url:string = "rest/docuwiz/document/download";
                                                const docId:string = item.docId;
                                                const docVersion:number = item.fileVersion;
                                                const defFileName:string = item.fileName;
                                                downloadFile({
                                                    url: url,
                                                    docId: docId,
                                                    defFileName: defFileName,
                                                    docVersion: docVersion
                                                }).then();
                                            }}/>
                                        </Tooltip >,
                                        <Tooltip title="파일정보" key="edit" arrow={false}>
                                            <InfoCircleOutlined onClick={(e) => {
                                                e.stopPropagation();
                                                setFileInfoData(item);
                                                setShowComment(false);
                                                setShowSideSlide(true);

                                            }}/>
                                        </Tooltip >
                                    ]}
                                    extra={
                                        <Tooltip placement="bottomLeft" title={item.fileName} arrow={false}>

                                        <div className="thumb_filename">
                                            {
                                                viewerOption?.mode === "rw" && (
                                                    <div className="thumb_filename left">
                                                        <Checkbox
                                                            value={item.docId}
                                                        />
                                                    </div>
                                                )
                                            }


                                            <div className="thumb_filename right">{item.fileName}</div>
                                        </div>
                                        </Tooltip>
                                    }
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        onCheckBoxChange(item.docId);

                                    }}
                                    onContextMenu ={(e) => {

                                        e.preventDefault();
                                        setCurData(item);

                                    }}
                                >
                                    <ThumbContainer onDeleteFile={onDeleteFile} fileInfo={item}/>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                </Checkbox.Group>
            : <EmptyCompnt/>
        }

        </div>

       }/>
    );
};

export default ThumbListCompnt;

