import React, {useContext} from 'react';
import {Dropdown, MenuProps} from "antd";
import ViewerDetailContext from "../../../../../context/ViewerDetailContext";
import {DocumentList} from "../data/DocumentList";
import useDownloadFile from "../../../../../common/hooks/rest/useDownloadFile";
import ViewerOptionContext from "../../../../../context/ViewerOptionContext";

interface RightButtonMenuPrps<T>{
    curData:T,
    setCurData:(value:T)=>void;
    dataComponent:React.ReactNode,

}
function RightButtonMenu<T> (props:RightButtonMenuPrps<T>)
{
    const { setDocId, setShowVersion, setShowSideSlide, setFileInfoData, setShowComment } = useContext(ViewerDetailContext);
    const { viewerOption } = useContext(ViewerOptionContext);

    const downloadFile = useDownloadFile();

    const items: MenuProps['items'] = [
        {
            label: '파일보기',
            key: 'preview',
        },
        {
            label: '파일다운로드',
            key: 'download',
        },
        {
            label: '코멘트보기',
            key: 'comment',
        },
        {
            label: '파일버전관리',
            key: 'file_version',
        },
        {
            label: '파일세부정보',
            key: 'propery',
        },

    ];

    const handleMenuClick: MenuProps['onClick'] = (e) => {
        const key:string = e.key;
        console.log(props.curData);
        let data = props.curData as DocumentList;
        switch (e.key)
        {
            case 'preview':
                const popUpOptions:string = "toolbar=no,scrollbars=no,resizable=yes,status=no,menubar=no,width=1200, height=800, top=0,left=0";
                window.open("../../view/"+viewerOption?.encryptInterfaceId+"/"+data.docId, viewerOption?.encryptInterfaceId, popUpOptions);
            break;
            case 'comment':
                setFileInfoData(data);
                setShowComment(true);
                setShowSideSlide(true);
                break;
            case 'download':
                const url:string = "rest/docuwiz/document/download";
                const docId:string = data.docId;
                const docVersion:number = data.fileVersion;
                const defFileName:string = data.fileName;
                downloadFile({
                    url: url,
                    docId: docId,
                    defFileName: defFileName,
                    docVersion: docVersion
                }).then();
            break;
            case 'file_version':

                setDocId(data.docId);
                setShowVersion(true);
            break;
            case 'propery':
                setFileInfoData(data);
                setShowComment(false);
                setShowSideSlide(true);

            break;
        }
        let empty:T;
        // @ts-ignore
        props.setCurData(empty);
    };



    const menuProps = {
        items,
        onClick: handleMenuClick,
    };

    return (
        <Dropdown menu={menuProps} trigger={['contextMenu']}>
            {props.dataComponent}
        </Dropdown>
    )
}

export default RightButtonMenu;