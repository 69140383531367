import React from 'react';
import './App.css';
import DocuWizRouter from "./router/DocuWizRouter";

function App() {
  return (
      <DocuWizRouter/>
  );
}

export default App;
