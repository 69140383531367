import {useParams, useNavigate} from "react-router-dom";

import React, {useEffect} from "react";
import {LoginParam, LoginResponse, procLogin} from "../../rest/login/LoginRest";
import LoadingContainer from "../../common/component/LoadingContainer/LoadingContainer";
import ErrPage from "../../common/pages/ErrPage";
import {useCommonUseQuery} from "../../common/hooks/rest/ReacQueryHook";
import {QueryKey} from "@tanstack/react-query";

const LoginPage = () =>
{
    const { param } = useParams() as { param: string };
    const loginSvcCode:string       =   process.env.REACT_APP_LOGIN_SERVICE as string;
    const requestPageSize:number       =   parseInt(process.env.REACT_APP_REQUET_PAGE_SIZE as string) ;

    const loginParam:LoginParam = {
        param:param,
        requestPageSize:requestPageSize
    }

    const queryKey:QueryKey =   [param];
    const { data, isLoading, isError } = useCommonUseQuery<LoginResponse>(
                                                                                                    queryKey,
                                                                                    () =>procLogin(loginSvcCode, loginParam));

    useEffect(() => {
        if (data && !isError) {
            console.log(data);

            sessionStorage.setItem("authorization", data.jwtToken.token);
            sessionStorage.setItem("refreshToken", data.jwtToken.refreshToken);
            sessionStorage.setItem("user_nm", data.jwtSession.userName);

            window.location.href = `/${data.jwtToken.openParam}`;
            //navigate(`/${data.viewerOption.encryptInterfaceId}`, { replace: true });


        }
    }, [data]);

    if (isLoading) {
        return (
            <LoadingContainer message="사용자 인증 중..."/>
        );
    }

    if (isError) {
        return (
            <ErrPage errCode={9998}/>
        );
    }

    return (
        <ErrPage errCode={9999}/>

    );



}
export default LoginPage;