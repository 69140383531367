import React from 'react';
import {Button, Empty} from 'antd';

const EmptyCompnt: React.FC = () => {
    return (
            <Empty
                style={{marginTop:'80px'}}
                image="/image/docuwizviewer/empty.svg"
                imageStyle={{ height: 100 }}
                description={
                    <span>
                        문서파일을 끌어서 넣거나, <br/>버튼을 통해 <a href="#API"><b>문서</b></a>를 첨부하세요
                      </span>
                }
            >

                <Button type="primary" style={{ position: 'relative', overflow: 'hidden', padding: 0 }}>
                    <label
                        htmlFor="uploadfile"
                        style={{
                            cursor: 'pointer',
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            opacity: 0,
                        }}
                    ></label>
                    <b style={{ padding: '0 15px' }}>문서 첨부</b>
                </Button>



            </Empty>
    );
};

export default EmptyCompnt;