import { useCallback } from 'react';
import { Modal } from 'antd';

interface ModalProps {
    title: string;
    content: string;
    type?: 'info' | 'warning' | 'error' | 'success';
}

const useModal = () => {
    const openModal = useCallback((props: ModalProps) => {
        Modal[props.type || 'info']({
            title: props.title,
            content: props.content,
        });
    }, []);

    return { openModal };
};

export default useModal;
