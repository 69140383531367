import axiosInstance from "../../common/util/AxiosInstance";
import {DocumentList} from "../../component/index/content/Viewer/data/DocumentList";

export interface documentParam {
    docId: string,
    docVer: number
}
export async function  procGetFileAllVersion(docId:string):Promise<DocumentList[]>{

    const response = await axiosInstance.request<DocumentList[]>(
        {
            method: "GET",
            url: `rest/docuwiz/document/version/manage/${docId}`,
            headers: {
                'Content-Type': 'application/json',
            },

        });

    return response.data;
};
export async function  procDeleteFileVersion(data:documentParam):Promise<boolean>{

    const response = await axiosInstance.request<boolean>(
        {
            method: "DELETE",
            url: `rest/docuwiz/document/version/manage/deletever/${data.docId}/${data.docVer}`,
            headers: {
                'Content-Type': 'application/json',
            },

        });

    return response.data;
};


export async function  procActiveDocVer(data:documentParam):Promise<DocumentList>{

    const response = await axiosInstance.request<DocumentList>(
        {
            method: "POST",
            url: `rest/docuwiz/document/version/manage/activever/${data.docId}/${data.docVer}`,
            headers: {
                'Content-Type': 'application/json',
            },

        });

    return response.data;
};