import React, {CSSProperties, useContext, useEffect, useState} from "react";
import FileAvatar from "../FileListViewer/FileAvatar";
import useDownloadToURL from "../../../../../common/hooks/rest/useDownloadToURL";
import {Badge, Button, Tooltip} from "antd";
import {CommentOutlined, DeleteOutlined} from "@ant-design/icons";
import {DocumentList} from "../data/DocumentList";
import ViewerOptionContext from "../../../../../context/ViewerOptionContext";
import ViewerDetailContext from "../../../../../context/ViewerDetailContext";
import useWebSocketApi from "../../../../../common/hooks/websocket/websockethook";

interface ThumbContainerProps {
    fileInfo:DocumentList;
    onDeleteFile(fileName:string, docId:string) :void;
}

function ThumbContainer(item:ThumbContainerProps)
{
    const { viewerOption } = useContext(ViewerOptionContext);
    const { setShowSideSlide,  setFileInfoData, setShowComment } = useContext(ViewerDetailContext);

    const url:string = "rest/docuwiz/document/download/file";
    const [previewId, setPreviewId] = useState<string>(item.fileInfo.previewId);
    const [prevFileType, setPrevFileType] = useState<number>(item.fileInfo.prev_file_type);
    const [cnvtStatus, setCnvtStatus] = useState<number>(item.fileInfo.cnvt_status);

    let isDownload:boolean  =   false;
    if( (prevFileType === 0 && cnvtStatus===1) || (cnvtStatus === 1 || prevFileType === 2) )
    {
        isDownload =true;
    }

    let isConnWs:boolean  =   cnvtStatus===0 ? true : false;

    const { downloadUrl } = useDownloadToURL({
        url: url, // The download API endpoint
        fileId: previewId, // The ID of the file to download
        isDownload: isDownload, // Set to true to start the download immediately
    });

    const tempUrl:string  = isConnWs? "/image/base_thumb/thumbLoading.svg":"/image/base_thumb/docu.svg";

    const tempUrlStyle: CSSProperties = {
        height: "140px",
    };

    const thumbStyle:CSSProperties = {
        width:'100%',
        height :'auto',
    }

    let thumbContainerStyle:CSSProperties= tempUrlStyle;

    if (downloadUrl) {
        thumbContainerStyle = thumbStyle;
    }

    type ConvertResult = {
        previewId: string;
        prevFileType: number;
        cnvtStatus:number;
    };

    const wsUrl:string = `${process.env.REACT_APP_API_WEBSOCKT_URL}wsocket/${item.fileInfo.fileId}`;
    // 웹소켓을 통해 받아온 데이터
    const wsData = useWebSocketApi<ConvertResult>(wsUrl, isConnWs);
    useEffect(() => {
        if (wsData) {
            console.log(wsData);
            setPreviewId(wsData.previewId);
            setPrevFileType(wsData.prevFileType);
            setCnvtStatus(wsData.cnvtStatus)
        }
    }, [wsData]);

    return (
        <div className="thumbContainer">
            <div className="thumbContainer-icon">
                <FileAvatar  extension={item.fileInfo.extension}/>
            </div>
            <div className="thumbContainer-area">
                <img style={thumbContainerStyle} className="thumbContainer-img"
                     alt={item.fileInfo.fileName}
                     src={downloadUrl ? downloadUrl : tempUrl}
                     draggable={false}
                />
                { viewerOption?.mode === "rw" && (
                    <Tooltip title="파일 삭제" arrow={false}>
                        <Button className="thumbContainer-button"  icon={<DeleteOutlined />} onClick={(e) => {
                            e.stopPropagation();
                            item.onDeleteFile(item.fileInfo.fileName, item.fileInfo.docId);
                        }} />
                    </Tooltip>
                    )

                }
                <Tooltip placement="bottomLeft" title="코멘트 보기" arrow={false}>
                    <Badge className="thumbContainer-button2"  dot={item.fileInfo.commentCount > 0 ? true :false}>
                        <Button  icon={<CommentOutlined />} onClick={(e) => {
                            e.stopPropagation();
                            setFileInfoData(item.fileInfo);
                            setShowComment(true);
                            setShowSideSlide(true);
                        }} />
                    </Badge>
                </Tooltip>

            </div>
        </div>
    );
}

export default ThumbContainer