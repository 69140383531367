import { createContext } from "react";
import {DocumentList} from "../component/index/content/Viewer/data/DocumentList";

export interface ViewerDetailContextType {
    showSideSlide: boolean|null;
    setShowSideSlide: (value: boolean|null) => void;
    fileInfoData:DocumentList|null;
    setFileInfoData: (value: DocumentList|null) => void;
    docId:string|null,
    setDocId: (value: string|null) => void,
    showVersion: boolean|null;
    setShowVersion: (value: boolean|null) => void;
    showComment: boolean|null;
    setShowComment: (value: boolean|null) => void;
    refetchViewer: boolean|null;
    setRefetchViewer: (value: boolean|null) => void;
}

const ViewerDetailContextValue: ViewerDetailContextType = {
    showSideSlide: false,
    setShowSideSlide: () => {},
    fileInfoData:null,
    setFileInfoData: () => {},
    docId:null,
    setDocId:() => {},
    showVersion: false,
    setShowVersion: () => {},
    showComment: false,
    setShowComment: () => {},
    refetchViewer: false,
    setRefetchViewer: () => {},
};

const ViewerDetailContext = createContext<ViewerDetailContextType>(
    ViewerDetailContextValue
);
export default ViewerDetailContext;