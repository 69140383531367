import React, {useEffect, useState} from 'react';
import {Layout, theme} from 'antd';
import {useParams} from "react-router-dom";
import '../index/Layout.css';
import {FileImageOutlined, FileOutlined} from "@ant-design/icons";
import {DocumentDetail, DocumentList} from "../../component/index/content/Viewer/data/DocumentList";
import {DetailViewerOption} from "../../data/ViewerOption";
import axiosInstance from "../../common/util/AxiosInstance";
import UserAvatar from "../../component/index/header/UserAvatar/UserAvatar";
import LoadingContainer from "../../common/component/LoadingContainer/LoadingContainer";
import ErrPage from "../../common/pages/ErrPage";
import {ViewerType} from "../../component/view/FileViewer/data/ViewerType";
import FullViewer from "../../component/view/FileViewer/FullViewer";
import FileListDropDown from "../../component/view/header/FileListDropDown/FileListDropDown";

const { Header, Content } = Layout;


const DocuwizPopupLayout: React.FC = () => {

    const { encryptInterfaceId } = useParams() as { encryptInterfaceId: string };
    const { encryptDocId } = useParams() as { encryptDocId: string };
    const [ isLoading, setLoading] = useState<boolean>(true);
    const [ fileData, setFileData] = useState<DocumentDetail>();
    const [ currentFileId, setCurrentFileId] = useState<string>();
    const [ viewData, setViewData] = useState<ViewerType>()

    const {
        token: { colorBgContainer },
    } = theme.useToken();

    const userName:string  =   sessionStorage.getItem("user_nm") as string;


    const requestParam :DetailViewerOption ={
        interfaceId : encryptInterfaceId,
        currentDocId:encryptDocId
    }
    const fileDataUrl:string = "rest/docuwiz/document/select";
    async function  getFileData(){

        await axiosInstance.request<DocumentDetail>(
            {
                method: "GET",
                url: fileDataUrl,
                headers: {
                    'Content-Type': 'application/json',
                },
                params:   requestParam
            })
            .then(response => {
                setFileData(response.data);

                const curFileInfo:DocumentList   =   response.data.currentInfo;
                const initViewerType:ViewerType = {
                    docId:curFileInfo.docId,
                    previewId:curFileInfo.previewId,
                    fileName:curFileInfo.fileName,
                    interfaceId:encryptInterfaceId,
                    convertStatus:curFileInfo.cnvt_status,
                    previewType:curFileInfo.prev_file_type,
                    version:curFileInfo.fileVersion,

                }
                setViewData(initViewerType);
                setLoading(false);
            })
            .catch(error =>
            {
                console.log(error.response)
                setLoading(false);
            });
    }

    useEffect(() => {
        getFileData().then();
    }, []);

    useEffect(() => {
        if (currentFileId && fileData)
        {
            for (let i = 0; i < fileData.documentInfo.length; i++) {
                const fileInfo = fileData.documentInfo[i];
                if(fileInfo.fileId === currentFileId)
                {
                    const viewerType:ViewerType = {
                        docId:fileInfo.docId,
                        previewId:fileInfo.previewId,
                        fileName:fileInfo.fileName,
                        interfaceId:encryptInterfaceId,
                        convertStatus:fileInfo.cnvt_status,
                        previewType:fileInfo.prev_file_type,
                        version:fileInfo.fileVersion,

                    }

                    setViewData(viewerType);
                    break;
                }

            }
        }
    }, [currentFileId]);

    if(fileData && viewData)
    {
        const fileDatas:{ title: JSX.Element; value: string }[] = fileData.documentInfo.map(file => {
            return {
                title: <span>{file.prev_file_type===2 ? <FileImageOutlined /> :  <FileOutlined />} {file.fileName}</span>,
                value: file.fileId
            };
        });


        return (
            <Layout style={{ minHeight: '100vh' }}>
                    <Header style={{
                                padding: '8px 9px',
                                background: colorBgContainer,
                                height: '50px',
                                display:'flex',
                                justifyContent:'space-between',
                                alignItems:'center',
                                borderBottom: '1px solid #dadada',

                    }}>
                            <FileListDropDown fileData={fileDatas} setCurrentFileId={setCurrentFileId} currentData={fileData.currentInfo.fileId} />
                            <UserAvatar userName={userName}/>
                    </Header>
                    <Content  style={{
                        margin:'8px 8px 8px 8px',
                        minHeight: 200,
                        height: 'calc(100vh - 80px)',

                    }}
                    >
                        <FullViewer {...viewData}/>
                    </Content>
            </Layout>
        );
    }



    if(isLoading){
        return (
            <LoadingContainer message="파일 데이터를 불러오는 중..."/>
        );
    }
    else {
        return (
            <ErrPage errCode={9998}/>
        );
    }



};

export default DocuwizPopupLayout;