import React from 'react';
import {TreeSelect} from 'antd';

interface TreeData {
    title : JSX.Element,
    value:string
}

interface FileListDropDownProps{
    fileData:TreeData[],
    currentData:string,
    setCurrentFileId:(value : string)=>void
}
function FileListDropDown(props :FileListDropDownProps){

    function handleSelect(info:any) {
        props.setCurrentFileId(info);
    }

    return (
        <TreeSelect
            onSelect={handleSelect}
            dropdownStyle={{ maxHeight: 400, overflow: 'auto', minWidth:'50%', maxWidth: '50%' }}
            placeholder="파일을 선택해주세요."
            dropdownMatchSelectWidth={false}
            defaultValue={props.currentData}
            allowClear
            treeDefaultExpandAll
            treeData={props.fileData}
        />
    );
}

export default FileListDropDown;