import React, {useState} from 'react';
import {Button, DatePicker, Table, Tag, theme, Tooltip} from "antd";
import {ColumnsType} from "antd/es/table";
import {QueryKey, useQueryClient} from "@tanstack/react-query";
import {useCommonUseQuery} from "../../../common/hooks/rest/ReacQueryHook";
import Search from "antd/es/input/Search";
import {DocumentList} from "../../index/content/Viewer/data/DocumentList";
import {
    formatDate,
    getCurrentDateTime,
    getYesterdayDateTime
} from "../../../common/util/DateUtil";
import UserAvatar from "../../index/header/UserAvatar/UserAvatar";
import dayjs from "dayjs";
import {procGetRestApiList, RestApiOption, TB_MONITORING} from "../../../rest/admin/MonitorRestApi";
import {procGetStorageWizRestApiList} from "../../../rest/admin/StorageWizMgr";

const MonitorStorageWizRest: React.FC = () => {
    const { token: { colorBgContainer },} = theme.useToken();
    const { RangePicker } = DatePicker;
    const [restApiOpt, setRestApiOpt] = useState<RestApiOption>(
        {
            traceId:null,
            startTime:getYesterdayDateTime(),
            endTime:getCurrentDateTime(),
            userId:null,
            success:null,
        }
    )
    const queryClient = useQueryClient();
    const queryKey:QueryKey =   [`Monitoring/StorageWizRest`];
    const { data} = useCommonUseQuery<TB_MONITORING[]>(
        queryKey,
        () =>procGetStorageWizRestApiList(restApiOpt));

    const onSearch = (value: string) => console.log(value);


    const columns: ColumnsType<TB_MONITORING> = [
        {
            title: 'LoginID',
            dataIndex: 'loginId',
            key: 'loginId',
            render: (text: string) =>
                <div style={{ paddingBottom: '9px', margin:'0', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <UserAvatar userName={text}/>
                </div>
            ,
        },
        {
            title: '추적ID',
            dataIndex: 'traceId',
            key: 'traceId',
            render: (text: string) =>
                <div style={{ paddingBottom: '9px', margin:'0', display: 'flex', alignItems: 'left', justifyContent: 'left' }}>
                    <a>{text}</a>
                </div>
            ,
        },
        {
            title: 'URL',
            dataIndex: 'reqUrl',
            key: 'reqUrl',
            render: (text: string) =>
                <div style={{ paddingBottom: '9px', margin:'0', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    {text}
                </div>
            ,
        },
        {
            title: '접속IP',
            dataIndex: 'clientIP',
            key: 'clientIP',
            render: (text: string) =>
                <div style={{ paddingBottom: '9px', margin:'0', display: 'flex', alignItems: 'center', justifyContent: 'center', width:80 }}>
                    {text}
                </div>
            ,
        },
        {
            title: '수행시간',
            dataIndex: 'runTime',
            key: 'runTime',
            render: (text: boolean) =>
                <div style={{ paddingBottom: '9px', margin:'0', display: 'flex', alignItems: 'center', justifyContent: 'center', width:80 }}>
                    {text} ms
                </div>
            ,
        },

        {
            title: '성공여부',
            dataIndex: 'success',
            key: 'success',
            render: (text: boolean) =>
                <div style={{
                    paddingBottom: '9px',
                    margin: '0',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: 50
                }}>
                    <span style={{
                        display: 'inline-block',
                        height: '15px',
                        width: '15px',
                        backgroundColor: text ? 'green' : 'red',
                        borderRadius: '50%',
                    }}/>
                </div>
            ,
        },
        {
            title: 'METHOD',
            key: 'method',
            dataIndex: 'method',
            render: (text: string) =>
                <div style={{ paddingBottom: '9px', margin:'0', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    {text}
                </div>
            ,
        },
        {
            title: '요청Body',
            key: 'requestBody',
            dataIndex: 'requestBody',
            render: (text: string) =>
                <Tooltip placement="top" title={text}>
                    <div style={{
                        paddingBottom: '9px',
                        margin: '0',
                        display: 'flex',
                        alignItems: 'left',
                        justifyContent: 'left',
                        overflow: 'hidden',     // Add this
                        textOverflow: 'ellipsis', // And this
                        whiteSpace: 'nowrap',      // And this
                        width:200,
                    }}>
                        {text}
                    </div>
                </Tooltip>
            ,
        },
        {
            title: '응답Body',
            key: 'responseBody',
            dataIndex: 'responseBody',
            render: (text:string) =>
                <Tooltip placement="top" title={text}>
                    <div style={{
                        paddingBottom: '9px',
                        margin: '0',
                        display: 'flex',
                        alignItems: 'left',
                        justifyContent: 'left',
                        overflow: 'hidden',     // Add this
                        textOverflow: 'ellipsis', // And this
                        whiteSpace: 'nowrap',      // And this
                        width:200,
                    }}>
                        {text}
                    </div>
                </Tooltip>
            ,
        },
        {
            title: '에러내용',
            key: 'exceptionTxt',
            dataIndex: 'exceptionTxt',
            render: (text:string) =>
                <Tooltip placement="top" title={text}>
                    <div style={{
                        paddingBottom: '9px',
                        margin: '0',
                        display: 'flex',
                        alignItems: 'left',
                        justifyContent: 'left',
                        overflow: 'hidden',     // Add this
                        textOverflow: 'ellipsis', // And this
                        whiteSpace: 'nowrap',      // And this
                        width:200,
                    }}>
                        {text}
                    </div>
                </Tooltip>
            ,
        },

    ];

    return (
        <>
        <nav style={{
            margin:'0px 0px 14px 0px',
            padding:'9px 8px 9px 0px',
            height: '50px',
            display:'flex',
            justifyContent: "space-between",
            alignItems:'center',
            borderRadius: "8px", // 라운드 처리 추가
            border: '1px solid #dadada',
            background: colorBgContainer,
        }}>
            <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{
                    padding: '9px 5px',
                    display:'flex',
                }}>
                    <RangePicker
                        defaultValue={[dayjs(restApiOpt.startTime, 'yyyy-MM-dd HH:mm:ss'), dayjs(restApiOpt.endTime, 'yyyy-MM-dd HH:mm:ss')]}
                        format={'YYYYMMDD'}
                    />


                </div>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
                <Search placeholder="검색어를 입력해보세요." allowClear onSearch={onSearch} style={{ width: 210 }} /> <Button type="primary" size="small">조회</Button>
            </div>
        </nav>
        <Table columns={columns}
               dataSource={data}
               rowKey="docId"
        />
        </>
    );
};

export default MonitorStorageWizRest;
