import React, {useContext} from "react";
import {Button,    message, Space, Tooltip} from "antd";
import {

     DeleteOutlined,
     FolderAddOutlined,

    UploadOutlined
} from "@ant-design/icons";
import FileUploadButton from "./FileUploadButton";
import SortButton from "./SortButton";
import ViewerOptionContext from "../../../../../context/ViewerOptionContext";



interface buttonParam {
    checkedItems : React.Key[],
    setSortBy:(value : string)=>void;
    setShowDeleteBox : (value : boolean) => void;
}

function RightButtonGroup (prop : buttonParam)
{

    const { viewerOption } = useContext(ViewerOptionContext);

    const deleteFile = () => {
        if(prop.checkedItems.length > 0)
        {
            prop.setShowDeleteBox(true);
        }
        else
        {
            message.info("삭제할 데이터를 선택해주세요.")
        }
    };

    return (
        <Space.Compact  style={{
            width:'100%',
            justifyContent:'flex-end',
        }} block>
            {
                viewerOption?.mode === "rw" && (
                    <>
                    <FileUploadButton fileTagId="uploadfile" iconComponent={<UploadOutlined/>} tooltip="파일첨부" />
                    <FileUploadButton fileTagId="uploadfolder" iconComponent={<FolderAddOutlined/>} tooltip="폴더첨부" />
                    <Tooltip title="문서파일 삭제" arrow={false}>
                        <Button type="primary" onClick={deleteFile} icon={<DeleteOutlined />} ></Button>
                    </Tooltip>
                    </>
                )
            }


            <SortButton setSortBy={prop.setSortBy}/>
        </Space.Compact>
    );

}

export default RightButtonGroup;