import { useState, useEffect } from 'react';
import axiosInstance from '../../util/AxiosInstance';

type DownloadOptions = {
    url: string;
    fileId: string;
    isDownload?: boolean;
};

function useDownloadToURL({ url, fileId, isDownload = true }: DownloadOptions) {
    const [downloadUrl, setDownloadUrl] = useState<string | null>(null);
    const [downloadStatus, setDownloadStatus] = useState<'idle' | 'downloading' | 'completed'>('idle');

    useEffect(() => {
        const downloadFile = async () => {
            try {
                if (fileId === null ) {
                    return null;
                }

                setDownloadStatus('downloading');

                const axiosResponse = await axiosInstance.request({
                    method: 'get',
                    url: `${url}`,
                    responseType: 'blob',
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                    params: { fileId: fileId },
                });

                const blobFile = new Blob([axiosResponse.data]);
                const objectUrl = window.URL.createObjectURL(blobFile);
                setDownloadUrl(objectUrl);
                setDownloadStatus('completed');
            } catch (error) {
                console.error('Error downloading file:', error);
                setDownloadStatus('idle');
            }
        };

        if (url && isDownload) {
            downloadFile();
        }

        return () => {
            if (downloadUrl) {
                window.URL.revokeObjectURL(downloadUrl);
            }
        };
    }, [url, fileId, isDownload]);

    return { downloadUrl: fileId !== null ? downloadUrl : null, downloadStatus };
}

export default useDownloadToURL;
