import React from 'react';
import {Empty, Spin} from "antd";

interface LoadingProps {
    message:string,
    size:string,
}


function LoadingContainer(props:LoadingProps)
{
    if(props.size === "big" || props.size === "BIG")
    {
        return (

            <div style={{
                width:'100%',
                height:'100%',
                overflow:'hidden',
                position:'fixed',
                top:'0',
                right:'0',
                bottom:'0',
                left:'0',
                backgroundColor:'rgba(255,255,255,0.8)',
                zIndex:'100',
                textAlign:'center',
            }}>
                <Empty
                    style={{
                        marginTop:'145px',}}
                    image="/image/loading/loading.svg"
                    imageStyle={{ height: 100 }}
                    description={
                    <span>
                       {props.message}
                      </span>
                    }
                />
            </div>

        );
    }

    return (
        <Spin tip={props.message} size="large"/>
    );

}

LoadingContainer.defaultProps = {
    message:"로딩 중..",
    size:"big",
};

export default LoadingContainer;
