import axiosInstance from "../../common/util/AxiosInstance";
import {DocumentList} from "../../component/index/content/Viewer/data/DocumentList";

export interface DocumentOption {
    documentName:string|null;
    documentId:string|null;
    userId:string|null;
    userName:string|null;
    startRegdate:string;
    endRegdate:string;

}

export async function  procGetDocumentList(docOpt:DocumentOption):Promise<DocumentList[]>{

    const response = await axiosInstance.request<DocumentList[]>(
        {
            method: "GET",
            url: `rest/docuwiz/admin/document/select`,
            headers: {
                'Content-Type': 'application/json',
            },
            params:docOpt,
        });

    return response.data;
};