import React from 'react';
import {Result} from "antd";

const getErrorMsg = (errCode:number): (number | string)[] =>
{
    let errTitle:string;
    let errMessage:string;
    switch(errCode)
    {
        //http 에러
        case 400 :
            errTitle = "400 Bad Request";
            errMessage = " 잘못된 문법으로 인하여 서버가 요청을 이해할 수 없습니다.";
            break;
        case 401 :
            errTitle = "401 Unauthorized";
            errMessage = "요청한 응답을 받기 위해서는 반드시 인증해야 합니다.";
            break;
        case 404 :
            errTitle = "404 Not Found";
            errMessage = "요청받은 리소스를 찾을 수 없습니다.";
            break;
        case 405 :
            errTitle = "405 Method Not Allowed";
            errMessage = "요청한 메소드는 서버에서 알고 있지만, 제거되었고 사용할 수 없습니다.";
            break;
        case 406 :
            errTitle = "406 Not Acceptable";
            errMessage = "서버가 서버 주도 콘텐츠 협상 을 수행한 이후, 사용자 에이전트에서 정해준 규격에 따른 어떠한 콘텐츠도 찾지 않았을 때, 웹서버가 보냅니다.";
            break;
        case 407 :
            errTitle = "407 Proxy Authentication Required";
            errMessage = "프록시에 의해 완료된 인증이 필요합니다.";
            break;
        case 408 :
            errTitle = "408 Request Timeout";
            errMessage = "요청한 작업에 응답이 없습니다.";
            break;
        case 409 :
            errTitle = "409 Conflict";
            errMessage = "서버의 상태와 충돌되었습니다.";
            break;
        case 410 :
            errTitle = "410 Gone";
            errMessage = "요청한 콘텐츠가 서버에서 영구적으로 삭제되었으며, 전달해 줄 수 있는 주소 역시 존재하지 않습니다.";
            break;
        case 411 :
            errTitle = "411 Length Required";
            errMessage = "Content-Length 헤더 필드가 정의되지 않은 요청이 들어왔기 때문에 서버가 요청을 거절합니다.";
            break;
        case 412 :
            errTitle = "412 Precondition Failed";
            errMessage = "클라이언트의 헤더에 있는 전제조건은 서버의 전제조건에 적절하지 않습니다.";
            break;
        case 413 :
            errTitle = "413 Payload Too Large";
            errMessage = "요청 엔티티는 서버에서 정의한 한계보다 큽니다.";
            break;
        case 414 :
            errTitle = "414 URI Too Long";
            errMessage = "클라이언트가 요청한 URI는 서버에서 처리하지 않기로 한 길이보다 깁니다.";
            break;
        case 415 :
            errTitle = "415 Unsupported Media Type";
            errMessage = "요청한 미디어 포맷은 서버에서 지원하지 않습니다.";
            break;
        case 416 :
            errTitle = "416 Requested Range Not Satisfiable";
            errMessage = "Range 헤더 필드에 요청한 지정 범위를 만족시킬 수 없습니다.<br/>범위가 타겟 URI 데이터의 크기를 벗어났을 가능성이 있습니다.";
            break;
        case 417 :
            errTitle = "417 Expectation Failed";
            errMessage = "Expect 요청 헤더 필드로 요청한 예상이 서버에서는 적당하지 않습니다.";
            break;
        case 418 :
            errTitle = "418 I'm a teapot";
            errMessage = "서버는 커피를 찻 주전자에 끓이는 것을 거절합니다.";
            break;
        case 421 :
            errTitle = "421 Misdirected Request";
            errMessage = "서버로 유도된 요청은 응답을 생성할 수 없습니다.";
            break;
        case 422 :
            errTitle = "423 Locked";
            errMessage = "요청은 잘 만들어졌지만, 문법 오류로 인하여 따를 수 없습니다.";
            break;
        case 423 :
            errTitle = "422 Unprocessable Entity";
            errMessage = "리소스는 접근하는 것이 잠겨있습니다.";
            break;
        case 424 :
            errTitle = "424 Failed Dependency";
            errMessage = "이전 요청이 실패하였기 때문에 지금의 요청도 실패하였습니다.";
            break;
        case 426 :
            errTitle = "426 Upgrade Required";
            errMessage = "서버는 지금의 프로토콜을 사용하여 요청을 처리하는 것을 거절하였지만, 클라이언트가 다른 프로토콜로 업그레이드를 하면 처리를 할지도 모릅니다.";
            break;
        case 428 :
            errTitle = "428 Precondition Required";
            errMessage = "오리진 서버는 요청이 조건적이어야 합니다.";
            break;
        case 429 :
            errTitle = "429 Too Many Requests";
            errMessage = "사용자가 지정된 시간에 너무 많은 요청을 보냈습니다.";
            break;
        case 431 :
            errTitle = "431 Request Header Fields Too Large";
            errMessage = "요청한 헤더 필드가 너무 크기 때문에 서버는 요청을 처리하지 않을 것입니다.";
            break;
        case 451 :
            errTitle = "451 Unavailable For Legal Reasons";
            errMessage = "사용자가 요청한 것은 정부에 의해 검열된 웹 페이지와 같은 불법적인 리소스입니다.";
            break;
        case 500 :
            errTitle = "500 Internal Server Error";
            errMessage = "서버가 처리 방법을 모르는 상황이 발생했습니다.";
            break;
        case 501 :
            errTitle = "501 Not Implemented";
            errMessage = "요청 방법은 서버에서 지원되지 않으므로 처리할 수 없습니다.";
            break;
        case 502 :
            errTitle = "502 Bad Gateway";
            errMessage = "서버가 요청을 처리하는 데 필요한 응답을 얻기 위해 게이트웨이로 작업하는 동안 잘못된 응답을 수신했습니다.";
            break;
        case 503 :
            errTitle = "503 Service Unavailable";
            errMessage = "서버가 요청을 처리할 준비가 되지 않았습니다.";
            break;
        case 504 :
            errTitle = "504 Gateway Timeout";
            errMessage = "서버가 게이트웨이 역할을 하고 있으며 적시에 응답을 받을 수 없습니다.";
            break;
        case 505 :
            errTitle = "505 HTTP Version Not Supported";
            errMessage = "요청에 사용된 HTTP 버전은 서버에서 지원되지 않습니다.";
            break;
        case 506 :
            errTitle = "506 Variant Also Negotiates";
            errMessage = "서버에 내부 구성 오류가 있습니다.";
            break;
        case 507 :
            errTitle = "507 Insufficient Storage";
            errMessage = "서버에 내부 구성 오류가 있습니다.";
            break;
        case 508 :
            errTitle = "508 Loop Detected";
            errMessage = "서버가 요청을 처리하는 동안 무한 루프를 감지했습니다.";
            break;
        case 510 :
            errTitle = "510 Not Extended";
            errMessage = "서버가 요청을 이행하려면 요청에 대한 추가 확장이 필요합니다.";
            break;
        case 511 :
            errTitle = "510 Not Extended";
            errMessage = "네트워크 액세스를 얻기 위해 인증을 받아야 할 필요가 있습니다.";
            break;

        //프로그램 에러
        case 9997:
            errTitle = "9997-초기 데이터 조회 실패";
            errMessage = "초기 데이터 조회 실패하여 뷰어를 열수 없습니다.";
            break;
        case 9998:
            errTitle = "9998-로그인 실패";
            errMessage = "로그인을 실패했습니다.";
            break;
        default :
            errTitle = "Wrong approach.";
            errMessage = "잘못된 접근입니다.";
            break;
    }
    return [errCode, errTitle, errMessage ];
}

interface ErrProps{
    errCode:number;
}


export default function ErrPage(props:ErrProps)
{
    const [errCode, errTitle, errMessage] = getErrorMsg(props.errCode);

    if ( errCode === 9999 )
    {
        return (
            <>
                <Result
                    status="403"
                    title={errMessage}
                    subTitle={errTitle}
                    extra={"오류가 지속되면 담당자에게 연락해주세요."}
                />
            </>
        );
    }
    if ( errCode === 404 )
    {
        return (
            <>
                <Result
                    status="404"
                    title={errMessage}
                    subTitle={errTitle}
                    extra={"오류가 지속되면 담당자에게 연락해주세요."}
                />
            </>
        );
    }
    else
    {
        return (
            <>
                <Result
                    status="500"
                    title={errTitle}
                    subTitle={errMessage}
                    extra={"오류가 지속되면 담당자에게 연락해주세요."}
                />
            </>
        );
    }

}