import axiosInstance from "../../common/util/AxiosInstance";

export interface TB_SECURITY_AES {
    aesKeyVer:string,
    aesKey:string,
    lastRegTime:string,
}

export async function  procGetAesMgrList():Promise<TB_SECURITY_AES[]>{

    const response = await axiosInstance.request<TB_SECURITY_AES[]>(
        {
            method: "GET",
            url: `rest/docuwiz/admin/security/aes/select`,
            headers: {
                'Content-Type': 'application/json',
            },
        });

    return response.data;
};